import React, { useEffect, useState } from "react"
import styled from "styled-components";
import { Button, Modal } from "react-bootstrap";
import { useLoggedInState } from "../globalStates/LoggedInUser";
import { GetConnectionDetails, setInitialStates, ConnectionStates } from "../SayHello";
import { doConnectAction, sendOrganizationConnectionRequest, loadExhibitorData, loadUserData, SpeakerResponse, trackVisit, BackendServiceError } from "../backendServices/BackendServices";
import branding, { LocalizedString } from "../branding/branding";
import { useLanguageState } from "../globalStates/LanguageState";
import { IconConnect, IconConnectSendRequest, IconConnectWithdraw, IconAlertSuccessfulRequest, IconRemoveStaff, IconCallBack } from "./Icons";
import { defaultLogger as logger } from "../globalStates/AppState"
import DataUpdateAlert from "./DataUpdateAlert";
import TextLimit from "./TextLimit"

export interface SayHelloModalBranding {
    cancelConnectionMessage: LocalizedString
    cancelConnectionTitle: LocalizedString
    ignoredMessage: LocalizedString
    openRequestMessage: LocalizedString
    cancelRequestMessage: LocalizedString
    sendMessagePlaceholder: LocalizedString
    headerTextSayHello: LocalizedString
    headerTextCancelRequest: LocalizedString
    headerTextOrganizationRequest: LocalizedString
    submitTextAccept: LocalizedString
    submitTextIgnore: LocalizedString
    submitTextYes: LocalizedString
    submitTextSend: LocalizedString
    submitTextClose: LocalizedString
    subtitleRegisterInterestsPart1: LocalizedString
    subtitleRegisterInterestsPart2: LocalizedString
    subtitleRegisterInterestsPart3: LocalizedString
    subtitleConnectRequestPart1: LocalizedString
    subtitleConnectRequestPart2: LocalizedString
    alertMessage: LocalizedString
    openRequestMessageModal: LocalizedString
    submitTextForCancelButton: LocalizedString
    submitBtnPrimaryBgColor: string
    submitBtnPrimaryTextColor: string
    submitBtnPrimaryBorderColor: string
    closeButtonColor: string
    submitBtnPrimaryOnHoverBgColor: string
    submitBtnPrimaryOnHoverTextColor: string
    submitBtnSecondaryBgColor: string
    submitBtnSecondaryTextColor: string
    submitBtnSecondaryBorderColor: string
    cancelText: LocalizedString
    cancelTextForCancelButton: LocalizedString
    cancelTextYes: LocalizedString
}

export const ModalRoot = styled(Modal)`
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};

    svg {
        color: ${branding.primaryColor}
    }

    .close{
        text-shadow: none;
        color: ${branding.mainInfoColor};
    }

    .modal-header-close{
        outline: 0;
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 26px;
        font-weight: 10;
        line-height: 1.66666667;
        color: ${branding.mainInfoColor};
        width: 30px;
        padding: 0 4px;
        background: none;
        border-style: none;
        margin-right: 0px;
        margin-top: -10px;
    }
  
    .modal-content{
        font-family: ${branding.font1};
        border-radius: 0px;
    }
    
    .modal-header{
        justify-content: left;
    }

    .modal-footer{
        justify-content: space-between;
        border: none;
        padding: 0px 15px;
    }

    .modal-title{
        font-family: ${branding.font1};
        margin-left: 15px;
        font-size: 16px;
        font-weight: normal;
        margin-top: 3px;
    } 

    .btn-primary  {
        font-family: ${branding.font1};
        width: 40%;
        font-size: 12px;
        display: inline;
        background-color: ${branding.sayHelloModal.submitBtnPrimaryBgColor ?? "#000"};
        color: ${branding.sayHelloModal.submitBtnPrimaryTextColor ?? "#fff"};
        border: 2px solid ${branding.sayHelloModal.submitBtnPrimaryBorderColor ?? "#000"};
        border-radius: 20px;
    
        :hover {
            background-color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverBgColor ?? "#e8e8e8"} !important; 
            color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverTextColor ?? "#000"} !important;
        }
    }

    .btn-secondary  {
        display: inline;
        /* width: 40%; */
        font-size: 12px;
        font-family: ${branding.font1};
        color: ${branding.sayHelloModal.submitBtnSecondaryTextColor ?? "#000"} !important;
        background-color: ${branding.sayHelloModal.submitBtnSecondaryBgColor ?? "#fff"};
        border: none;
        border-color: ${branding.sayHelloModal.submitBtnSecondaryBorderColor ?? "#fff"};;
        border-radius: none;
        }

    .subtitleText {
        font-family: ${branding.font1};
        display: absolute;
        align-items: center;
        font-size: 14px;
        margin: 10px 20px 10px 20px;
    }

    .headerImage{
        margin-top: 5px;
        margin-left: 5px;
    }

    .customString{
        font-weight: bold;
    }

    .messageBox{
      display: flex;
      font-size: 12px;
      margin-left: 8px;
      font-family: ${branding.font1};
    }
`

export const TextareaRoot = styled.div` 
    .form-group{
        font-size: 12px;
    }
    .form-control{
        font-size: 12px;
    }
    menu,ol,ul,li {
        list-style: inside; 
    }
`
interface ModalContentProps {
    requested: boolean,
    accepted: boolean,
    ignored: boolean,
    openRequest: boolean,
    message?: string
    onChange(e: React.ChangeEvent<HTMLTextAreaElement>): void,
}

const ModalContent = (props: ModalContentProps) => {
    const strings = useLanguageState().getStrings();
    const maxTextLength: number = 150;
    const message = props.accepted ? strings.sayHelloModal.cancelConnectionMessage :
        props.ignored ? strings.sayHelloModal.ignoredMessage :
            props.openRequest ? strings.sayHelloModal.openRequestMessage : strings.sayHelloModal.cancelRequestMessage;

    if (!props.accepted && !props.requested && !props.ignored && !props.openRequest) {
        return (
            <TextareaRoot className="form-group">
                <textarea
                    maxLength={maxTextLength}
                    autoFocus={true}
                    value={props.message}
                    name="modalInputMessage"
                    onChange={props.onChange}
                    className="form-control"
                    placeholder={strings.sayHelloModal.sendMessagePlaceholder}></textarea>
                <TextLimit textLength={props.message?.length || 0} maxTextLength={maxTextLength} />
            </TextareaRoot>
        )
    } else {
        return (
            <TextareaRoot className="form-group">
                <span className="messageBox">{message}</span>
            </TextareaRoot>
        )
    }
}

interface OrganizationRequestModalContentProps {
    message?: string,
    onChange(e: React.ChangeEvent<HTMLTextAreaElement>): void
}

const RequestOrganizationModalContent = (props: OrganizationRequestModalContentProps) => {
    const strings = useLanguageState().getStrings();
    const maxTextLength: number = 150;
    return (
        <TextareaRoot className="form-group">
            <textarea
                maxLength={maxTextLength}
                value={props.message}
                name="modalInputMessage"
                onChange={props.onChange}
                className="form-control"
                placeholder={strings.sayHelloModal.sendMessagePlaceholder}></textarea>
            <TextLimit textLength={props.message?.length || 0} maxTextLength={maxTextLength} />
        </TextareaRoot>
    )
}

interface ModalProps {
    handleClose(): void;
    children?: React.ReactNode;
    targetId: string;
    setSayHelloResponseStatus?(status: string): void,
    setCurrentConnectionStatus?(status: string): void,
    organizationModal?: boolean
    removePosition?: boolean
}

const IgnoreButton = styled.div`
    /* width: 90px;
    height: 40px;
    font-size: 18px;
    border: none;
    background: white;
    color: #FFF;
    border-radius: 25px;
    cursor: pointer; */
    width: 50%;
    height: 40px;
    cursor: pointer;
    font-size: 12px;
    font-family: ${branding.font1};
    color: ${branding.primaryColor};
    border: none;
    
    &:hover{
        text-decoration: none;
    }
`
export const SubmitButton = styled.div`
    width: 200px;
    height: 40px;
    cursor: pointer;
    font-size: 18px;
    
`
export const SubmitButtonWithoutIgnore = styled.div`
    width: 80%;
    height: 40px;
    cursor: pointer;
    font-size: 18px;
    margin-left: 45px;
    margin-top: -10px;

    .btn-primary{
        background-color: ${branding.sayHelloModal.submitBtnPrimaryBgColor ?? "#000"};
        color: ${branding.sayHelloModal.submitBtnPrimaryTextColor ?? "#fff"};
        border-color: ${branding.sayHelloModal.submitBtnPrimaryBorderColor ?? "#000"};
    }
    
`
export const SubmitButtonContainer = styled.div`
    width: 100%;
    justify-content: space-between;
    display: inline-flex;
    margin-bottom: 20px;
`
export const DisagreeButton = styled.div`
    width: 50%;
    height: 30px;
    cursor: pointer;
    font-size: 12px;
    font-family: ${branding.font1};
    color: ${branding.primaryColor};
    
    &:hover{
        text-decoration: none;
    }
`
export const SubmitButtonNew = styled(Button)`
    width: 50%;
    color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
    background-color: ${branding.sayHelloModal.submitBtnPrimaryBgColor}!important;
    border: 2px solid ${branding.sayHelloModal.submitBtnPrimaryBorderColor}!important;
    border-radius: 20px;
    font-size: 12px;
    font-family: ${branding.font1};

    :hover {
        background-color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverTextColor} !important; 
    }
`

const SayHelloModal = (props: ModalProps) => {
    const userLink = useLoggedInState();
    const loggedInUserId = userLink.user()?.profileId || "";

    const [isLoaded, setIsLoaded] = useState(false);

    const [openRequest, setOpenRequest] = useState(false);
    const [requested, setRequested] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [ignored, setIgnored] = useState(false);
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const strings = useLanguageState().getStrings();

    const headerText = props.organizationModal ? strings.sayHelloModal.headerTextOrganizationRequest : (requested || accepted ? requested ? strings.sayHelloModal.headerTextCancelRequest : strings.sayHelloModal.cancelConnectionTitle : openRequest ? strings.sayHelloModal.openRequestMessageModal : strings.sayHelloModal.headerTextSayHello);
    const submitText = openRequest ? strings.sayHelloModal.submitTextAccept : accepted ? strings.sayHelloModal.submitTextForCancelButton : requested || ignored ? strings.sayHelloModal.submitTextYes : strings.sayHelloModal.submitTextSend;
    const closeText = openRequest ? strings.sayHelloModal.cancelText : accepted ? strings.sayHelloModal.cancelTextForCancelButton : requested || ignored ? strings.sayHelloModal.cancelTextYes : strings.sayHelloModal.cancelText;
    const headerImage = props.organizationModal ? IconCallBack({ fill: branding.sideIconBar.sideIconColorDark }) : requested ? IconConnectWithdraw({ fill: branding.sideIconBar.sideIconColorDark }) : accepted ? IconRemoveStaff({ fill: branding.sideIconBar.sideIconColorDark }) : openRequest ? IconConnectSendRequest({ fill: branding.sideIconBar.sideIconColorDark }) : IconConnect({ fill: branding.sideIconBar.sideIconColorDark });
    const [modalInputMessage, setmodalInputMessage] = useState<string>("");

    const [name, setName] = useState<string>("");

    useEffect(() => {
        if (props.organizationModal) {
            loadExhibitorData({
                organizationid: props.targetId,
                hideNewsdata: false
            }).then((resp) => {
                setName(resp.content?.name);
            }).catch((error: { message: React.SetStateAction<string> }) => {
                logger.error("SayHelloModal load Exhibitor Data failed", error.message)
            })
        }
        else {
            loadUserData({
                targetProfileId: props.targetId,
                loggedInUserId: loggedInUserId as string
            }).then((response) => {
                const content = (response as SpeakerResponse).content
                setName([content.firstName, content.lastName]
                    .filter(Boolean)
                    .join(" "));
            }).catch((error: { message: React.SetStateAction<string> }) => {
                logger.error({ message: "SayHelloModal load User Data failed", errorMessage: error.message })
            })
        }
        // eslint-disable-next-line
    }, [])


    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setmodalInputMessage(e.target.value);
    }

    function handleSubmit(event: React.FormEvent<HTMLButtonElement>) {
        if (openRequest) {
            if (event.currentTarget.innerHTML === strings.sayHelloModal.submitTextIgnore) {
                connectActionHandler("ignore", null);
                props.handleClose()
            }
            else {
                connectActionHandler("accept", null);
                props.handleClose()
            }

        } else if (requested || accepted || ignored) {
            connectActionHandler("cancel", null);
            setAccepted(false);
            setRequested(false);
            setIgnored(false);
            setOpenRequest(false);
            setmodalInputMessage("");
            props.handleClose()
        }
        else {
            connectActionHandler("request", modalInputMessage);
            //setShowAlert(true);
        }
        //props.handleClose()
    }

    function handleOrganizationRequest(event: React.FormEvent<HTMLButtonElement>) {
        sendOrganizationConnectionRequest({
            profileId: loggedInUserId as string,
            organizationId: props.targetId,
            message: modalInputMessage,
        }).then(resp => {
            if ((resp as BackendServiceError).httpStatus) {
                // FIXME
            } else {
                trackVisit(loggedInUserId as string, props.targetId, 'INTEREST#SENT')
            }

            props.handleClose();
        }).catch(err => {
            // Logged in BackendServices
        })
    }

    //function which makes POST request to /doConnectAction endpoint
    function connectActionHandler(action: string, message: string | null) {

        doConnectAction({
            profileId: loggedInUserId as string,
            targetProfileId: props.targetId,
            message: message,
            action: action,
        }).then((response: any) => {
            if (response.content) {
                const status = response.content.profile.myConnectionStatus;
                const obj = setInitialStates(status);
                if (props.setSayHelloResponseStatus)
                    props.setSayHelloResponseStatus(status)
                setValues(obj);

                if (status === ConnectionStates.REQUESTED) {
                    setShowAlert(true);
                    return;
                }
            }

        }).catch((e: { message: React.SetStateAction<string> }) => {
            // Logged in BackendServices
        });
    }

    useEffect(() => {
        if (props.organizationModal) {
            setIsLoaded(true)
        } else {
            GetConnectionDetails(loggedInUserId, props.targetId).then(res => {
                const obj = setInitialStates(res as string);
                if (props.setCurrentConnectionStatus)
                    props.setCurrentConnectionStatus(res as string)
                setValues(obj);
                setIsLoaded(true);
            }).catch(error => {
                logger.error({ message: "SayHelloModal Get Connect Details failed", errorMessage: error.message, errorStack: error.stack });
            })
        }
        //eslint-disable-next-line
    }, [props.targetId]);

    function setValues(obj: any) {
        setRequested(obj.requested);
        setOpenRequest(obj.openRequest);
        setAccepted(obj.accepted);
        setIgnored(obj.ignored);
    }


    let content: JSX.Element = <div />
    if (isLoaded && props.organizationModal) {
        content = <div>
            <div className="modal-header">
                <span className="headerImage">{headerImage}</span> <h3 className="modal-title">{headerText}</h3>
                <button type="button" className="close" onClick={props.handleClose} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="subtitleText">
                {props.organizationModal && name &&
                    <span>{strings.sayHelloModal.subtitleRegisterInterestsPart1}<span className="customString">{name}</span>{strings.sayHelloModal.subtitleRegisterInterestsPart2}
                        <span className="customString">{strings.sayHelloModal.subtitleRegisterInterestsPart3 ? name : ""}</span>{strings.sayHelloModal.subtitleRegisterInterestsPart3}</span>
                }
            </div>
            <div className="modal-body">
                <RequestOrganizationModalContent onChange={handleChange} message={modalInputMessage}></RequestOrganizationModalContent>
            </div>
            <div className="modal-footer">
                <SubmitButtonContainer>
                    {/* <button type="button" className="btn btn-secondary" onClick={props.handleClose}>
                    {closeText}
                </button>
                <button type="submit" className="btn btn-primary" onClick={handleOrganizationRequest}>
                    {submitText}
                </button> */}
                    <DisagreeButton onClick={props.handleClose} className="d-flex align-items-center">{closeText}</DisagreeButton>
                    <SubmitButtonNew type="submit" onClick={handleOrganizationRequest} className="d-flex align-items-center justify-content-center">{submitText}</SubmitButtonNew>
                </SubmitButtonContainer>
            </div>
        </div>
    }

    else if (isLoaded) {
        content = <div>
            <div className="modal-header">
                <span className="headerImage">{headerImage}</span> <h3 className="modal-title">{headerText}</h3>
                <button type="button" className="close" onClick={props.handleClose} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="subtitleText">
                {(!requested && !accepted && !openRequest && !ignored) && name && <span>{strings.sayHelloModal.subtitleConnectRequestPart1}<span className="customString">{name} </span>{strings.sayHelloModal.subtitleConnectRequestPart2}</span>}
            </div>
            <div className="modal-body">
                <ModalContent requested={requested} accepted={accepted} ignored={ignored} onChange={handleChange} message={modalInputMessage} openRequest={openRequest}></ModalContent>
            </div>
            <div className="modal-footer">
                <SubmitButtonContainer>
                    {openRequest && <IgnoreButton className="d-flex align-items-center" onClick={() => handleSubmit} hidden={!openRequest}>{strings.sayHelloModal.submitTextIgnore}</IgnoreButton>}
                    {openRequest && <SubmitButtonNew type="submit" className="d-flex align-items-center justify-content-center" onClick={handleSubmit}>{submitText}</SubmitButtonNew>}

                    {!openRequest && <DisagreeButton hidden={openRequest} onClick={props.handleClose} className="d-flex align-items-center">{closeText}</DisagreeButton>}
                    {!openRequest && <SubmitButtonNew type="submit" hidden={openRequest} onClick={handleSubmit} className="d-flex align-items-center justify-content-center">{submitText}</SubmitButtonNew>}
                </SubmitButtonContainer>
            </div>
        </div>

    }

    return <>
        {!showAlert && <ModalRoot backdrop="static" show={true} animation={false} onHide={() => props.handleClose()}>
            <div onClick={e => e.stopPropagation()}>
                {content}
            </div>
        </ModalRoot>}
        {showAlert &&
            <DataUpdateAlert
                message={strings.sayHelloModal.alertMessage}
                show={showAlert}
                type='say-hello-modal'
                titleImage={IconAlertSuccessfulRequest}
                color='#00B300'
                timeoutSeconds={3}
                position='top'
                close={() => { setShowAlert(false); props.handleClose(); }}
            />}
    </>
}

export default SayHelloModal;
