
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import branding from "../branding/branding";
export interface CrsMultiSwitcherBranding {
    crsMultiSwitcherDefaultBgColor: string
    crsMultiSwitcherDefaultColor: string
    crsMultiSwitcherActiveBgColor: string
    crsMultiSwitcherActiveColor: string
    crsMultiSwitcherNotActiveColor: string
    crsMultiSwitcherBorder: string
    crsMultiSwitcherOnDarkThemeBorderColor: string
    crsMultiSwitcherOnDarkThemeBgColor: string
    crsMultiSwitcherOnDarkThemeColor: string
    crsMultiSwitcherActiveColorInMeeting: string
    crsMultiSwitcherMainWidth: string
    crsMultiSwitcherMultiSwitchItemFontSize: string
}

const MultiSwitchRoot = styled.div<{ width?: string, border?: string, padding: string }>`
    font-family: ${branding.font1};
    min-width: ${props => props.width ? props.width : 'auto'};
    position: relative;
    display: flex;
    display: -webkit-box;
    border: ${props => props.border ? props.border : branding.crsMultiSwitcher.crsMultiSwitcherBorder};
    border-radius: 15px;
    padding: ${props => props.padding};
    /* font-size: 1.1rem; */
    flex-basis: 0;
    user-select: none;
    height: 30px;
    color: ${branding.crsMultiSwitcher.crsMultiSwitcherNotActiveColor}; 

`
const MultiSwitchItem = styled.div<{ width: number, color?: string }>`
    width: ${props => props.width}%;
    text-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: ${branding.crsMultiSwitcher.crsMultiSwitcherMultiSwitchItemFontSize ?? "12px"};
    line-height: 17px;
    padding: .3rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    transition: .5s all;
    white-space: nowrap;
    /* color: ${branding.crsMultiSwitcher.crsMultiSwitcherNotActiveColor}; */
    &.active {
        /* color: rgba(255,255,255) */
        color: ${props => props.color ? props.color : branding.crsMultiSwitcher.crsMultiSwitcherActiveColor}
    }

    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1100px) {
      font-size: 10px;
    }
    
     @media (max-width: 1300px) {
      padding: .3rem .2rem;
    }
`

const MultiSwitchActiveItem = styled.div<{ width: number, left: string, padding: string }>`
    position: absolute;
    z-index: -1;
    height: ${props => `calc(100% - ${props.padding} - ${props.padding})`};
    width: ${props => `calc(${props.width}% - ${props.padding})`};
    left: ${props => props.left};
    border-radius: 20px;
    top: ${props => props.padding};
    transition: left .5s;
    background-color: ${branding.crsMultiSwitcher.crsMultiSwitcherActiveBgColor};
    white-space: nowrap;
    @media (max-width: 1100px) {
      font-size: 10px;
    }
`

interface CrsMultiSwitchItemProps {
    label: string,
    value: any
}
interface CrsMultiSwitchProps {
    /* Items to display */
    items: Array<CrsMultiSwitchItemProps>,
    /* Active item value */
    activeItem: any,
    /* On switch item click. Passing item value as a parameter */
    onItemClick: Function,
    /* Pass true to hide component */
    hideComponent?: boolean,
    style?: {
        /* Component padding */
        mainPadding?: string,
        /* Component width */
        mainWidth?: string,
        /* Border style - added for the adjustment of filter bar's and pagination's designs */
        border?: string,
        /* Root item additional css */
        msRootItem?: React.CSSProperties,
        /* Switch item additional css */
        msItem?: React.CSSProperties,
        /* Active item additional css */
        msActiveItem?: React.CSSProperties
        itemColor?: string
    }
}

const CrsMultiSwitch = (props: CrsMultiSwitchProps) => {
    const [activeItemWidth, setActiveItemWidth] = useState(0)
    const [activeItemLeft, setActiveItemLeft] = useState('')
    const [mainPadding, setMainPadding] = useState('1px')
    /* Calculating active item left value */
    const setActiveItemLeftValue = () => {

        let activeItemIndex = props.items.findIndex(x => x.value === props.activeItem)

        let leftValue = activeItemIndex * activeItemWidth;
        let left: string

        if (leftValue === 0)
            left = `calc(${leftValue}% + ${mainPadding})`
        else
            left = `${leftValue}%`

        setActiveItemLeft(left)
    }

    useEffect(() => {
        if (props.activeItem === undefined)
            return

        activeItemWidth === 0 ? setActiveItemWidth(100 / props.items.length) : setActiveItemLeftValue()

        // eslint-disable-next-line
    }, [props.activeItem])

    useEffect(() => {
        setActiveItemWidth(100 / props.items.length)
        // eslint-disable-next-line
    }, [props.items.length])

    useEffect(() => {
        if (!activeItemWidth)
            return
        setActiveItemLeftValue()
        // eslint-disable-next-line
    }, [activeItemWidth])

    useEffect(() => {
        if (!props.style || !props.style.mainPadding)
            return

        setMainPadding(props.style.mainPadding)
        // eslint-disable-next-line
    }, [props.style?.mainPadding])

    if (props.hideComponent) {
        return null;
    } else {
        return (
            <MultiSwitchRoot padding={mainPadding} width={props.style?.mainWidth} border={props.style?.border} style={props.style?.msRootItem}>
                <MultiSwitchActiveItem width={activeItemWidth} left={activeItemLeft} padding={mainPadding} style={props.style?.msActiveItem} />
                {activeItemLeft && props.items.map((item, index) => {
                    return (<MultiSwitchItem
                        key={index}
                        onClick={() => props.onItemClick(item.value)}
                        width={activeItemWidth}
                        style={props.style?.msItem}
                        color={props.style?.itemColor}
                        className={item.value === props.activeItem ? 'active' : ''}>{item.label}</MultiSwitchItem>)
                })}
            </MultiSwitchRoot>
        )
    }
}

export default CrsMultiSwitch
