import React, { useEffect, useRef, useState } from "react"
import Autosuggest, { BlurEvent, ChangeEvent, RenderSuggestionParams, SuggestionsFetchRequestedParams, Theme } from "react-autosuggest"
import styled from "styled-components"
import { loadSuggestions } from "../backendServices/BackendServices"
import { SuggestGroup, Suggestion } from "../backendServices/Types"
import branding from "../branding/branding"
import { useLanguageState } from "../globalStates/LanguageState"
import { useAppState } from "../globalStates/AppState"
import { AvatarWithDefault } from "./AvatarWithDefault"
import { IconChevronRight, IconClose, IconSearch } from "./Icons"
import { EntityType } from "../backendServices/Types"
import useWindowDimensions from "./WindowDimensionsHook"
import { useHistory } from "react-router-dom"
import { exhibitorsPageRoute } from "../navigationArea/RoutePaths"

/**
 * wrapper style, because suggestbox, e.g. autosuggest component cannot be styled
 */
const StyledAutoSuggestWrapper = styled.div<{ emptySearch: boolean, windowHeight: number }>`
    display: flex;

    &>:first-child, &>:last-child {
        position: absolute;
        top: 0;
        width: 40px;
        height: 100%;
        background-color:#fff;
        color: ${branding.primaryColor};
        text-align: center;
        line-height: 60px;
        z-index: 4;
    }

    &>:first-child {
        left: 0;
    }

    &>:last-child {
        right: 0;
        width: 40px;
        cursor: pointer;
    }

    .react-autosuggest__container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: -1px;
        z-index: 3;
        font-size: 18px;
        background: #fff;
        color: ${branding.primaryColor};
        border-bottom: 1px solid ${branding.primaryColor};
    }

    .react-autosuggest__input {
        width: calc(100% - 80px);
        height: 100%;
        border: 0 none;
        outline: none !important;
        padding-left: 20px;
        margin: 0 40px;
    }

    .react-autosuggest__suggestions-container {
        display: none;
        background: #fff;
        flex-wrap: wrap;
        border-top: 1px solid ${branding.primaryColor};
        border-bottom: 1px solid ${branding.primaryColor};

        &.react-autosuggest__suggestions-container--open {
            display: flex;
            position: relative;
            max-height: ${props => (props.windowHeight / 2) + 50 + 'px'};
            overflow: hidden auto;
        }
    }

    .react-autosuggest__section-container {
        width: calc(20% - 40px); /* -20px for padding left and right*/
        padding: 20px;

        @media(max-width: 1800px){
            width: calc(25% - 40px);
        }

        @media(max-width: 1600px){
            width: calc(33% - 40px);
        }
       
        &:last-child {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .react-autosuggest__section-title {
                display: none;
            }

            .react-autosuggest__suggestion {
                margin: 0;
                text-align: center;
                color: ${branding.exhibitorsPageContent.suggestBoxSearchAllButtonColor ?? "#FFF"};;
                background-color: ${branding.exhibitorsPageContent.suggestBoxSearchAllButtonBgColor ?? "#000"};
                padding: 10px 20px;
                display: inline-block;

                &:hover {
                    opacity: 0.9;
                }
            }
        }
    }

    .react-autosuggest__section-title {
        margin-bottom: 10px;
    }

    .react-autosuggest__suggestion {
        margin-bottom: 15px;
    }

    .react-autosuggest__suggestion--highlighted {
        cursor: pointer;
    }
`

export enum StaticSuggestionId {
    SHOWMORE_ENTITIES = "showMoreEntities",
    SEARCH_ALL = "searchAll"
}

interface SuggestBoxProps {
    onSuggestionChanged(suggestion: Suggestion): void
    onBlur: () => void
    theme?: Theme
}

// Hack to allow for visibility of suggest container even if there is no suggestion from the backend. For "searchAll"
// Suggestion container is not displayed if there are no suggestions
const searchAllSuggestGroup = { id: StaticSuggestionId.SEARCH_ALL, maxSuggestions: 0, name: "", suggestions: [{ icon: "", id: StaticSuggestionId.SEARCH_ALL, value: "", subtitle: "", title: "" }], trackingName: "" }

const SuggestBox: React.FunctionComponent<SuggestBoxProps> = (props: SuggestBoxProps) => {
    const { onSuggestionChanged, onBlur, theme } = props
    const [suggestGroups, setSuggestGroups] = useState<SuggestGroup[]>([])
    const [inputValue, setInputValue] = useState<string>("")
    const strings = useLanguageState().getStrings()
    const wrapperRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const appState = useAppState()
    const history = useHistory()
    const windowSize = useWindowDimensions();

    useEffect(() => inputRef.current?.focus(), [])

    useEffect(() => {
        function handleOutsideClick(this: Document, ev: MouseEvent) {
            if (wrapperRef.current && !wrapperRef.current.contains(ev.target as Node)) {
                onBlur()
            }
        }
        document.addEventListener('mousedown', handleOutsideClick)
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }

    }, [onBlur])

    // start searching all when "Enter" is pressed
    useEffect(() => {
        const input = document.querySelector(".react-autosuggest__input")
        if (!input)
            return

        const onEnterPressed = (e: any) => {
            if (e.keyCode === 13) { // keyCode for "Enter"
                const suggestion = { icon: "", id: StaticSuggestionId.SEARCH_ALL, value: e.target.value, subtitle: "", title: e.target.value }
                appState.setSuggestParam(suggestion)
                appState.setSuggestSearchVisible(false)
                history.push(exhibitorsPageRoute)
                // const x = (input as HTMLInputElement)
                // x.blur()
            }
        }

        input.addEventListener('keyup', onEnterPressed)
        return () => input?.removeEventListener('keyup', onEnterPressed)
    }, [document.querySelector(".react-autosuggest__input")]) //eslint-disable-line


    async function fetchSuggestions(request: SuggestionsFetchRequestedParams) {
        const suggestionResponse = await loadSuggestions(request.value, branding.exhibitorsPageContent.suggestGroups)
        const newSuggestGroups: SuggestGroup[] = []
        if (suggestionResponse.suggestGroups) {
            for (let suggestGroup of suggestionResponse.suggestGroups) {
                const type = getEntityTypeForTrackingName(suggestGroup.trackingName)
                if (type !== null) {
                    for (let suggestion of suggestGroup.suggestions) {
                        suggestion.type = type
                    }
                    // hiding "Show more" part for participants(EntityType -> person), because we don't have a page where all people are displayed
                    if (suggestGroup.trackingName !== "person") {
                        const title = strings.exhibitorsPageContent.suggestGroups.find(x => x.entityType?.toLowerCase() === suggestGroup.trackingName)?.showMoreTitle ?? strings.networkingArea.showMoreText
                        suggestGroup.suggestions.push({ id: StaticSuggestionId.SHOWMORE_ENTITIES, title: title, subtitle: "", value: request.value, icon: "", type: type })
                    }
                }
            }
            newSuggestGroups.push(...suggestionResponse.suggestGroups)
        }
        searchAllSuggestGroup.suggestions[0].value = request.value
        searchAllSuggestGroup.suggestions[0].title = strings.networkingArea.suggestSearchAll.split("{$suggestInput}").join(request.value)

        newSuggestGroups.push(searchAllSuggestGroup)
        setSuggestGroups(newSuggestGroups)
    }

    const inputProps = {
        placeholder: strings.sideIconBar.searchIconText,
        value: inputValue,
        onChange: (_event: React.FormEvent, params: ChangeEvent) => setInputValue(params.newValue),
        onBlur: (_event: React.FocusEvent, _params?: BlurEvent<any>) => onBlur(),
        ref: inputRef
    }


    return (
        <StyledAutoSuggestWrapper ref={wrapperRef} emptySearch={suggestGroups.length <= 1} windowHeight={windowSize.height}>
            <div style={{ marginLeft: "18px" }}>
                {IconSearch({ fill: "currentColor" })}
            </div>
            <Autosuggest
                theme={theme}
                multiSection={true}
                suggestions={suggestGroups}
                onSuggestionsFetchRequested={fetchSuggestions}
                onSuggestionsClearRequested={() => setSuggestGroups([])}
                getSuggestionValue={(suggestion) => suggestion.title}
                renderSectionTitle={(section: SuggestGroup) => renderSectionTitle(section, strings)}
                renderSuggestion={renderSuggestion}
                getSectionSuggestions={(section: SuggestGroup) => section.suggestions}
                onSuggestionSelected={(_event, data) => {
                    if (data.suggestion.id === StaticSuggestionId.SHOWMORE_ENTITIES) {
                        data.suggestion.title = strings.networkingArea.suggestNameContains.split("{$suggestion}").join(data.suggestion.value)
                    }
                    onSuggestionChanged(data.suggestion)
                    setInputValue("")
                }}
                inputProps={inputProps}
            />
            <div style={{ zIndex: 4 }} onClick={() => {
                onBlur()
                appState.clearSuggestParam()
            }}>{IconClose({ fill: "currentColor" })}</div>
        </StyledAutoSuggestWrapper>
    )
}

function renderSectionTitle(section: SuggestGroup, strings: any) {
    let name = section.name
    if (!name)
        switch (section.trackingName) {
            case "categories": name = strings.organizationDetailPageContent.sectionLineCategories; break;
            case "organization": name = strings.sideIconBar.companiesSearchDrawerText; break;
            case "person": name = strings.sideIconBar.peopleSearchDrawerText; break;
            case "news": name = strings.sideIconBar.newsSearchDrawerText; break;
            case "product": name = strings.sideIconBar.productsSearchDrawerText; break;
            case "trademark": name = strings.sideIconBar.trademarksSearchDrawerText; break;
            case "event_date": name = strings.sideIconBar.programSearchDrawerText; break;
            case "networking_user": name = strings.sideIconBar.networkingParticipantsSearchDrawerText; break;
        }
    return <span>{name}</span>
}

function getEntityTypeForTrackingName(trackingName: string): EntityType | null {
    let type: EntityType | null = null
    switch (trackingName) {
        case "organization": type = "organization"; break
        case "trademark": type = "trademark"; break
        case "product": type = "product"; break
        case "news": type = "news"; break
        case "event_date": type = "eventdate"; break
        case "person": type = "person"; break
        case "networking_user": type = "networking_user"; break
    }
    return type
}

const SuggestionRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
`
const StyledAvatar = styled(AvatarWithDefault)`
    flex-shrink: 0;
    border-radius: 0;
`

const SuggestionText = styled.div`
    flex-grow: 1;
    padding-left: 10px;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;


    .react-autosuggest__suggestion--highlighted & {
        text-decoration: underline;
    }

`

const SuggestionTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
`

const SuggestionSubtitle = styled.div`
    font-size: 12px;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
`

const ShowMoreLine = styled.div`
    font-weight: bold;
    font-size: 16px;

    .react-autosuggest__suggestion--highlighted & {
        text-decoration: underline;
    }
`

function renderSuggestion(suggestion: Suggestion, params: RenderSuggestionParams) {
    if (suggestion.id === StaticSuggestionId.SEARCH_ALL)
        return suggestion.title
    else if (suggestion.type && suggestion.id === StaticSuggestionId.SHOWMORE_ENTITIES)
        return <SuggestionRow>
            <ShowMoreLine>{suggestion.title}  {IconChevronRight({ width: "16px", height: "16px" })}</ShowMoreLine>
        </SuggestionRow>
    else
        return <SuggestionRow>
            <StyledAvatar alt={suggestion.title} src={suggestion.icon} size={40} />
            <SuggestionText>
                <SuggestionTitle>{suggestion.title}</SuggestionTitle>
                <SuggestionSubtitle>{suggestion.subtitle}</SuggestionSubtitle>
            </SuggestionText>
        </SuggestionRow>
}

export default SuggestBox;
