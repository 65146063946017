import React from "react";
import { IconChat, IconCamera, IconCalendarEntry, IconRecommend, IconDecline, IconBlockContact, IconDownloadVCard, IconConnect, IconReport, IconBookmarkButton, IconBookmarkButtonFilled, VGIcon, IconConnectWithdraw, IconRemoveStaff } from "../ui/Icons"
import { syncFavorites, doConnectAction, BackendServiceError, MakeConnectionResponse, CalendarEntry, findChatConversation, setMuteStatus } from "../backendServices/BackendServices"
import moment from "moment"
import { ChatConversationParam } from "./ChatPage"
import { MeetingStatusCode } from "../conference/context/ChimeContext"
import { saveVCard } from "./VCard"
import { setInitialStates } from "../SayHello"
import { Modal, Button } from "react-bootstrap"
import { useLanguageState } from "../globalStates/LanguageState";
import { useMeetingContext } from "../conference/context/MeetingContext";
import SayHelloModal from "../ui/SayHelloModal";
import CalendarEntryModal, { CalendarEntryModalViewMode } from "../ui/CalendarEntryModal";
import RecommendOrganizationModal from "../ui/RecommendOrganizationModal";
import GuestModal from "../ui/GuestModal";
import { ShareTargetType } from "../backendServices/Types";
import ReportModal from "../ui/ReportModal";
import { useContactState } from "./ContactState";
import styled from "styled-components";
import { User } from "../globalStates/LoggedInUser";
import { useAppState } from "../globalStates/AppState";
import { buildDetailLink } from "../contentArea/detailPages/DetailNavLink";
import { isExplorationOrPostEventPhase } from "../EventPhaseChecker";
import { hasAccessToUser } from "../Authorization";
import branding from "../branding/branding";
import { getIamPartOf } from "../globalStates/IAmPortOf";
import queryString from 'query-string'

const MainModal = styled(Modal)`
.modal-header {
  display: inline-flex;
  border-bottom: none;
}

.modal-body{
  font-size: 17px;
  display: flex;
}

.modal-footer {
    justify-content: space-between;
    border: none;
}

.close{
  outline: 0;
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 46px;
  font-weight: 10;
  line-height: 1.66666667;
  color: #000;
  width: 30px;
  padding: 0 2px;
  background: none;
  border-style: none;
  margin-right: 1px;
  margin-top: -16px;
}


.btn-primary  {
  width: 40%;
  display: inline-block;
  /* color: white !important; */
  /* background-color: #000; */
  /* border: 2px solid; */
  /* border-color: ${branding.primaryColor ?? "black"}; */
  border-radius: 20px;
/*   
      :hover {
      background-color: #e8e8e8 !important; 
      color: black !important;
      } */
  }

  .btn-secondary  {
    display: inline-block;
    /* color: black !important;
    background-color: white; */
    border: none;
    /* border-color: white; */
    /* border-radius: none; */
    }
`
const TitleImage = styled.div`
    font-size: 30px;
`
const Title = styled.div`
    margin-left: 20px;
    margin-top: 9px;
    font-size: 22px;
`
// const CancelButton = styled.div`
//     width: 60px;
//     height: 40px;
//     justify-content: left;
//     font-size: 18px;
//     border: none;
//     background: white;
//     color: #FFF;
//     border-radius: 25px;
//     cursor: pointer;
// `
const CancelButton = styled.div`
    width: 30%;
    height: 30px;
    cursor: pointer;
    font-size: 12px;
    font-family: ${branding.font1};
    color: ${branding.primaryColor};
    
    &:hover{
        text-decoration: none;
    }
`
const ConfirmButton = styled(Button)`
    width: 40%;
    height: 30px;
    color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
    background-color: ${branding.sayHelloModal.submitBtnPrimaryBgColor}!important;
    border: 2px solid ${branding.sayHelloModal.submitBtnPrimaryBorderColor}!important;
    border-radius: 20px;
    font-size: 12px;
    font-family: ${branding.font1};

    :hover {
        background-color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverTextColor} !important; 
    }
`
// const ConfirmButton = styled.div`
//     width: 40%;
//     height: 30px;
//     cursor: pointer;
//     font-size: 12px;
//     border-radius: 20px;
//     font-size: 12px;
//     font-family: ${branding.font1};
//     color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
//     background-color: ${branding.recommendModal.submitBtnPrimaryBgColor}!important;
//     :hover {
//         background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
//         color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important; 
//     }
// `

function getSayHelloTexts(status: string, strings: any) {
    const conn = setInitialStates(status)
    switch (conn.messageType) {
        case "cancelRequestMessage": return { hint: strings.contactEntry.cancelRequestMessage, title: strings.contactEntry.cancelRequestTitle, icon: IconConnectWithdraw({ fill: branding.sideIconBar.sideIconColorDark }) }
        case "cancelConnectionMessage": return { hint: strings.contactEntry.cancelConnectionMessage, title: strings.contactEntry.disconnectTitle, icon: IconRemoveStaff({ fill: branding.sideIconBar.sideIconColorDark }) }
        case "ignoredMessage": return { hint: strings.contactEntry.ignoredMessage, title: strings.contactEntry.connectTitle, icon: IconConnect({ fill: branding.sideIconBar.sideIconColorDark }) }
        case "blockedMessage": return { hint: strings.contactEntry.ignoredMessage, title: strings.contactEntry.connectTitle, icon: IconConnect({ fill: branding.sideIconBar.sideIconColorDark }) }
        case "openRequestMessage": return { hint: strings.contactEntry.openRequestMessageHint, title: strings.contactEntry.openRequestMessage, icon: IconConnect({ fill: branding.sideIconBar.sideIconColorDark }) }
        case "sendRequestMessage": return { hint: strings.contactEntry.sendRequestMessage, title: strings.contactEntry.connectTitle, icon: IconConnect({ fill: branding.sideIconBar.sideIconColorDark }) }
        default:
            return { title: "test", hint: "" }
    }
}

function doVCard(person: any) {
    saveVCard([person])
}

async function updateBlocked(loggedInUserId: any | undefined, person: any, contactState: any) {
    const isBlocking = contactState.getConnectionStatus(person.id) === 'BLOCKING'
    const response = await doConnectAction({
        profileId: loggedInUserId!,
        targetProfileId: person.id,
        action: isBlocking ? 'cancel' : 'block',
        message: null
    })

    let conversation = await findChatConversation(loggedInUserId, person.id)
    if (conversation) {
        setMuteStatus(conversation.userConversationId, !isBlocking)
    }

    if ((response as BackendServiceError).httpStatus) {
        // TODO ERROR
    } else {
        const newStatus = (response as MakeConnectionResponse).content.profile.myConnectionStatus;
        person.myConnectionStatus = newStatus
        contactState.setConnectionStatus(person.id, newStatus)
    }
}

export const doCall = (personId: string, meeting: any, chime: any, callback: (param: { modalType: 'none' | 'connect' | 'meeting' | 'call' | 'share' | 'report' | 'guest' }) => void) => {
    const meetingParam = meeting.getCurrentMeetingParam();
    if (meetingParam) {
        meeting.sendInvite(personId, meetingParam)
    } else if (chime.getMeetingStatus().meetingStatus === MeetingStatusCode.Succeeded)
        callback({ modalType: 'call' })
    else
        meeting.sendInvite(personId)
}

const doChat = (personId: string, appState: any) => {
    appState.setShowChatsTab(ChatConversationParam.privateConversationByOpponentId(personId))
}

const updateBookmarkedStatus = (loggedInUserId: any | undefined, person: any, favorites: any, date: Date, callback: (param: { bookmarkChanged: boolean }) => void, sotUser?: boolean) => {
    const userType = sotUser ? "sotuser" : "person"

    function syncFav(id: string) {
        syncFavorites({
            profileId: loggedInUserId,
            body: {
                currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                lastSyncTime: favorites.getLastSyncTime(),
                changedFavorites: [{
                    id: id,
                    kind: (userType).toUpperCase(),
                    deleted: favorites.is(userType, id) ? false : true,
                    lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                }]
            }
        }).then((resp) => {
            favorites.setLastSyncTime(new Date())
            callback({ bookmarkChanged: true })
        }).catch((e: { message: React.SetStateAction<string> }) => {
            // syncFavorites failed, logged in BackendServices
        })
    }

    if (loggedInUserId) {

        favorites.toggle(userType, person.id)
        syncFav(person.id)

        if (person.person && person.type === "speaker") {
            favorites.toggle("person", person.person)
            syncFav(person.person)
        }
    }
}

export interface ConfirmCallProps {
    show: boolean
    onHide: () => void
    sotUserId: string
}

export const ConfirmCall: React.FC<ConfirmCallProps> = ({ show, onHide, sotUserId }) => {
    const strings = useLanguageState().getStrings()
    const meeting = useMeetingContext()
    const appState = useAppState()

    return (
        <MainModal
            show={show}
            onHide={onHide}
            backdrop="static"
            centered
            animation={false}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ display: "inline-flex" }}>
                    <TitleImage>{IconCalendarEntry({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}</TitleImage>
                    <Title>{strings.conferenceTexts.changingRoomConfirmationTitle}</Title>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{strings.conferenceTexts.changingRoomConfirmationText}</Modal.Body>
            <Modal.Footer>
                <CancelButton onClick={onHide} className="d-flex align-items-center">{strings.globalTexts.cancel}</CancelButton>
                <ConfirmButton type="submit" onClick={() => {
                    appState.setIsMyHandRaised(false)
                    meeting.sendInvite(sotUserId)
                    onHide()
                }} className="d-flex align-items-center justify-content-center">
                    {strings.conferenceTexts.changingRoomConfirmationAccept ?? strings.globalTexts.confirm}
                </ConfirmButton>
            </Modal.Footer>
        </MainModal>
    )
}

export default ConfirmCall

export interface Action {
    disabled: boolean
    title: string
    hint: string
    icon: VGIcon
    onClick: () => void
}

export const chatAction = (connectionStatus: string, strings: any, personId: string, appState: any, disableUserButtons?: boolean, accessToUserGranted?: boolean, enableGuestModal?: Function) => {
    const userButtonsDisabled = disableUserButtons !== undefined ? disableUserButtons : false
    return {
        disabled: userButtonsDisabled || connectionStatus === 'BLOCKED' || connectionStatus === 'BLOCKING',
        title: strings.contactEntry.sendMessageTitle,
        hint: strings.contactEntry.sendMessageHint,
        icon: IconChat({ fill: branding.sideIconBar.sideIconColorDark }),
        onClick: () => {
            if (accessToUserGranted) {
                doChat(personId, appState)
            }
            else {
                if (enableGuestModal) {
                    enableGuestModal()
                }
            }
        }
    }
}

export const callAction = (connectionStatus: string, strings: any, personId: string, meeting: any, chime: any, callback: (param: { bookmarkChanged?: boolean, modalType?: 'none' | 'connect' | 'meeting' | 'call' | 'share' | 'report' | 'guest' }) => void, disableUserButtons?: boolean, accessToUserGranted?: boolean, enableGuestModal?: Function) => {
    let userIsInMeeting = false
    const queryParams: any = queryString.parse(window.location.search)
    const userButtonsDisabled = disableUserButtons !== undefined ? disableUserButtons : false
    // Only calculate if the Menu is open, 
    const roster = chime.getRoster()
    for (let rosterKey in roster) {
        if (roster[rosterKey].id === personId) {
            userIsInMeeting = true
            break;
        }
    }
    return {
        disabled: (isExplorationOrPostEventPhase && !(getIamPartOf(queryParams) === "onboarding")) || userButtonsDisabled || connectionStatus === 'BLOCKED' || connectionStatus === 'BLOCKING' || (meeting.getCurrentMeetingParam() !== undefined && (chime.hasMaxAttendees() || userIsInMeeting)),
        title: strings.contactEntry.startCallTitle,
        hint: meeting.getCurrentMeetingParam() ? strings.contactEntry.inviteToCallHint : strings.contactEntry.startCallHint,
        icon: IconCamera({ fill: branding.sideIconBar.sideIconColorDark }),
        onClick: () => {
            if (accessToUserGranted) {
                doCall(personId, meeting, chime, callback)
            }
            else {
                if (enableGuestModal) {
                    enableGuestModal()
                }
            }
        }
    }
}



export const createActions = (
    loggedInUser: User | undefined,
    person: any,
    favorites: any,
    contactState: any,
    appState: any,
    meeting: any,
    chime: any,
    strings: any,
    isBookmarked: boolean,
    connectionStatus: string,
    userType: string,
    callback: (param: { bookmarkChanged?: boolean, modalType?: 'none' | 'connect' | 'meeting' | 'call' | 'share' | 'report' | 'guest' }) => void,
    additionalActions?: Action[],
    disableUserButtons?: boolean,
    sotUser?: boolean) => {
    const isBlocked = connectionStatus === 'BLOCKED'
    const isBlocking = connectionStatus === 'BLOCKING'
    const isConnected = connectionStatus === 'CONNECTED'
    const date = new Date()
    const sayHello = getSayHelloTexts(connectionStatus, strings)

    const accessToUserGranted = hasAccessToUser(loggedInUser, person)

    function enableGuestModal() {
        callback({ modalType: 'guest' })
    }

    const userButtonsDisabled = disableUserButtons !== undefined ? disableUserButtons : false
    let result = [
        {
            disabled: isBlocked || isBlocking,
            title: isBookmarked ? strings.contactEntry.unbookmarkTitle : strings.contactEntry.bookmarkTitle,
            hint: isBookmarked ? strings.contactEntry.unbookmarkHint : strings.contactEntry.bookmarkHint,
            icon: isBookmarked ? IconBookmarkButtonFilled({ fill: branding.sideIconBar.sideIconColorDark }) : IconBookmarkButton({ fill: branding.sideIconBar.sideIconColorDark }),
            onClick: () => {
                if (accessToUserGranted) {
                    updateBookmarkedStatus(loggedInUser?.profileId, person, favorites, date, callback, sotUser)
                }
                else {
                    enableGuestModal()
                }
            }
        },
        chatAction(connectionStatus, strings, person.id, appState, userButtonsDisabled, accessToUserGranted, enableGuestModal),
        callAction(connectionStatus, strings, person.id, meeting, chime, callback, userButtonsDisabled, accessToUserGranted, enableGuestModal),
        {
            disabled: userButtonsDisabled || isBlocked || isBlocking,
            title: strings.contactEntry.requestMeetingTitle,
            hint: strings.contactEntry.requestMeetingHint,
            icon: IconCalendarEntry({ fill: branding.sideIconBar.sideIconColorDark }),
            onClick: () => {
                if (accessToUserGranted) {
                    callback({ modalType: 'meeting' })
                }
                else {
                    enableGuestModal()
                }
            }
        },
        {
            disabled: userButtonsDisabled || isBlocked || isBlocking || !loggedInUser?.matchActive,
            title: sayHello.title,
            hint: sayHello.hint,
            icon: sayHello.icon,
            onClick: () => {
                if (accessToUserGranted) {
                    callback({ modalType: 'connect' })
                }
                else {
                    enableGuestModal()
                }
            }
        },
        {
            disabled: isBlocked || isBlocking,
            title: strings.contactEntry.shareTitle,
            hint: strings.contactEntry.shareHint,
            icon: IconRecommend({ fill: branding.sideIconBar.sideIconColorDark }),
            onClick: () => {
                callback({ modalType: 'share' })
            }
        },
        {
            disabled: userButtonsDisabled || isBlocked,
            title: isBlocking ? strings.contactEntry.unblockPersonTitle : strings.contactEntry.blockPersonTitle,
            hint: isBlocking ? strings.contactEntry.unblockPersonHint : strings.contactEntry.blockPersonHint,
            icon: isBlocking ? IconDecline({ fill: branding.sideIconBar.sideIconColorDark }) : IconBlockContact({ fill: branding.sideIconBar.sideIconColorDark }),
            onClick: () => {
                if (accessToUserGranted) {
                    updateBlocked(loggedInUser?.profileId, person, contactState)
                }
                else {
                    enableGuestModal()
                }
            }
        },
        {
            disabled: !isConnected,
            title: strings.contactEntry.downloadVCardTitle,
            hint: strings.contactEntry.downloadVCardHint,
            icon: IconDownloadVCard({ fill: branding.sideIconBar.sideIconColorDark }),
            onClick: () => {
                if (accessToUserGranted) {
                    doVCard(person)
                }
                else {
                    enableGuestModal()
                }
            }
        },
        {
            disabled: isBlocking,
            title: strings.communicationArea.reportText,
            hint: strings.communicationArea.reportHint,
            icon: IconReport({ fill: branding.sideIconBar.sideIconColorDark }),
            onClick: () => {
                if (accessToUserGranted) {
                    callback({ modalType: 'report' })
                }
                else {
                    enableGuestModal()
                }
            }
        }

    ]
    if (additionalActions) {
        result = result.concat(additionalActions)
    }
    return result
}
export interface CommunicationModalsProps {
    show: 'none' | 'connect' | 'meeting' | 'call' | 'share' | 'report' | 'guest'
    contact: any
    calendarEntry?: CalendarEntry
    onHide: () => void
    onWithdrawRequest?: (contactId: string) => void
    removePosition?: boolean // remove absolute position when say hello is called inside meeting
}

export const CommunicationModals: React.FC<CommunicationModalsProps> = ({ contact, show, calendarEntry, onHide, onWithdrawRequest, removePosition }) => {

    const contactState = useContactState()

    switch (show) {
        case 'connect':
            return (
                <SayHelloModal
                    targetId={contact.id}
                    handleClose={onHide}
                    removePosition={removePosition}
                    setSayHelloResponseStatus={(connectStatus) => {
                        if (connectStatus === "UNRELATED" && onWithdrawRequest) {
                            onWithdrawRequest(contact.id);
                        }
                        contactState.setConnectionStatus(contact.id, connectStatus);
                    }}
                />
            );
        case 'meeting':
            return (<CalendarEntryModal calendarEntry={calendarEntry} viewMode={calendarEntry ? CalendarEntryModalViewMode.VIEW : CalendarEntryModalViewMode.CREATE} sotUser={contact ? [contact!] : undefined} close={onHide} />)
        case 'call':
            return (<ConfirmCall show={true} onHide={onHide} sotUserId={contact.id} />)
        case 'share': {

            const link = buildDetailLink(contact.person ?? contact.id, `/person/${contact.firstName}_${contact.lastName}`, (contact.person || contact.eventDates) ? "person" : "user")

            return (<RecommendOrganizationModal targetId={contact.id} type={ShareTargetType.SOTUSER} link={`https://${window.location.hostname}` + link} sotUser={[]} close={onHide}></RecommendOrganizationModal>)
        }
        case 'report': {
            return (<ReportModal show={true} onHide={onHide} userToReportId={contact.id} />)
        }
        case 'guest': {
            return <GuestModal close={onHide} />
        }

    }
    return null;
}
