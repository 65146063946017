import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { NavLink, useRouteMatch } from "react-router-dom"
import { IconArrowBreadcrumb } from "../ui/Icons"
import branding from "../branding/branding"
import { meetingPageRoute } from "./RoutePaths"


const BreadcrumbContainer = styled.div`
    padding: 15px 0 15px 30px;
    position: sticky;
`

const BreadcrumbRoot = styled.div<{ foregroundColor: string }>`
    display: flex;
    color: ${props => props.foregroundColor};
    font: ${branding.font1};
    flex-flow: row;
    flex-wrap: nowrap;
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
    align-items: center;
    height: 20px;
    font-weight: ${branding.topBar.fontWeight === "bold" ? "bold" : "normal"};
`

const BreadcrumbItemSimple = styled.span`
font-family: ${branding.font1};
font-size: 18px;
line-height: 20px;
text-transform: ${branding.topBar.breadcrumbTextTransform};
letter-spacing: ${branding.topBar.breadcrumbLetterSpacing};
`
export interface BreadcrumbItem {
    to: string
    name: string
}

interface BreadcrumbProps {
    breadcrumb: BreadcrumbItem[]
    noTracking?: boolean
    trackedLocation?: string
    setRef?: Function
}

const Breadcrumb: React.FunctionComponent<BreadcrumbProps> = (props: BreadcrumbProps) => {
    let isMeetingPage = useRouteMatch(meetingPageRoute)
    const breadcrumbRef = useRef<HTMLDivElement>(null)

    let textColor = isMeetingPage ? "#fff" : branding.mainInfoColor
    let textTransformValue: any = branding.topBar.breadcrumbTextTransform;

    var trackedLocation = props.trackedLocation
    if (!trackedLocation && !props.noTracking) {
        const l = props.breadcrumb.length
        if (l > 0) {
            const loc = props.breadcrumb[l - 1]
            if (loc && loc.to && loc.to !== '') {
                trackedLocation = loc.to
            }
        }
    }

    const buildBreadcrumbItem = (breadcrumbItem: BreadcrumbItem, index: number, last: boolean) => {

        if (!breadcrumbItem)
            return null
        if (last) {
            return <BreadcrumbItemSimple style={{ color: textColor }} key={index}>{breadcrumbItem.name}</BreadcrumbItemSimple>
        } else {
            return (
                <React.Fragment key={index}>
                    <NavLink style={{ fontFamily: branding.font1, color: textColor, fontSize: "18px", lineHeight: "20px", letterSpacing: branding.topBar.breadcrumbLetterSpacing, textTransform: textTransformValue }} to={breadcrumbItem.to}>{breadcrumbItem.name}
                    </NavLink>
                    <div style={{ marginRight: "5px", marginLeft: "5px" }}>
                        {IconArrowBreadcrumb({ fill: isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : "currentColor" })}
                    </div>
                </ React.Fragment>
            )
        }
    }

    useEffect(() => {
        if (breadcrumbRef && breadcrumbRef.current && props.setRef){
            props.setRef(breadcrumbRef)
        }
        // eslint-disable-next-line
    }, [breadcrumbRef])

    return <BreadcrumbContainer ref={breadcrumbRef}>
        <BreadcrumbRoot foregroundColor={textColor!}>
            {props.breadcrumb.map((breadcrumbItem, index) => buildBreadcrumbItem(breadcrumbItem, index, index >= props.breadcrumb.length - 1))}
        </BreadcrumbRoot>
    </BreadcrumbContainer>
}

export default Breadcrumb