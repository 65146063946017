import React, { useState } from "react"
import branding, { LocalizedString } from "../../branding/branding"
import ViewMyProfileLayout from "./ViewMyProfileLayout"
import EditMyProfilePageLayout, { SocialMediaType } from "./EditMyProfileLayout"
import TopBar from "../../navigationArea/TopBar"
import Breadcrumb from "../../navigationArea/Breadcrumb"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import { useLanguageState } from "../../globalStates/LanguageState"
import GuestUserBanner from "../guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../guestUserBanner/GuestUserBannerSharedState"

export interface OffersNeedsCategoryType {
    id: string
    name: LocalizedString
    placeholderBuying: LocalizedString
    placeholderSelling: LocalizedString
}

export interface MyProfilePageContentBranding {
    myProfileTitle: LocalizedString
    editProfileButtonText: LocalizedString
    noInterestsSelectedMessage: LocalizedString
    noOffersSelectedMessage: LocalizedString
    noNeedsSelectedMessage: LocalizedString
    goBackLabel: LocalizedString
    profilePictureLabel: LocalizedString
    uploadNewButtonText: LocalizedString
    formatsText: LocalizedString
    deleteCurrentPictureButtonText: LocalizedString
    nameLabel: LocalizedString
    surnameLabel: LocalizedString
    companyLabel: LocalizedString
    jobTitleLabel: LocalizedString
    phoneLabel: LocalizedString
    mobileLabel: LocalizedString
    timeZoneLabel: LocalizedString
    industryLabel: LocalizedString
    countryLabel: LocalizedString
    countrySwitcherText: LocalizedString
    languagesLabel: LocalizedString
    languagesSwitcherText: LocalizedString
    facebookLinkLabel: LocalizedString
    twitterLinkLabel: LocalizedString
    linkedInLinkLabel: LocalizedString
    xingLinkLabel: LocalizedString
    instagramLinkLabel: LocalizedString
    youTubeLinkLabel: LocalizedString
    pinterestLinkLabel: LocalizedString
    biographyLabel: LocalizedString
    numberOfCategories: number
    interestsLabel: LocalizedString
    imOfferingLabel: LocalizedString
    numberOfOffers: number
    imLookingToBuyLabel: LocalizedString
    numberOfNeeds: number
    showMoreButtonText: LocalizedString
    showLessButtonText: LocalizedString
    requiredFieldsLabel: LocalizedString
    saveAndUpdateButtonText: LocalizedString
    discardChangesButtonText: LocalizedString
    dataSuccessfullyUpdatedText: LocalizedString
    oneRequiredFieldEmptyWarning: LocalizedString
    requiredFieldsEmptyWarning: LocalizedString
    validationIncorrectWarning: LocalizedString
    invalidNameWarning: LocalizedString
    invalidSurnameWarning: LocalizedString
    invalidCompanyNameWarning: LocalizedString
    invalidJobTitleWarning: LocalizedString
    invalidIndustryNameWarning: LocalizedString
    invalidPhoneNumberWarning: LocalizedString
    invalidFacebookLinkWarning: LocalizedString
    invalidTwitterLinkWarning: LocalizedString
    invalidLinkedInLinkWarning: LocalizedString
    invalidXingLinkWarning: LocalizedString
    invalidInstagramLinkWarning: LocalizedString
    invalidYouTubeLinkWarning: LocalizedString
    invalidPinterestLinkWarning: LocalizedString
    pictureSizeWarning: LocalizedString
    backendErrorWarning: LocalizedString
    searchPlaceholder: LocalizedString
    minPictureHeight: number
    minPictureWidth: number
    editDisabledFields?: string[]
    availableSocialMedias: SocialMediaType[]
    offersNeedsCategories: Array<OffersNeedsCategoryType>
    categoriesOrder: string[]
}

export enum MyProfilePageMode { VIEW, EDIT }

const MyProfilePageContent: React.FunctionComponent = (props) => {
    const params = new URLSearchParams(window.location.search).get('mode')
    const [myProfileMode, setMyProfileMode] = useState<MyProfilePageMode>(params && params === "EDIT" ? MyProfilePageMode.EDIT : MyProfilePageMode.VIEW)
    const strings = useLanguageState().getStrings()
    const locations = calcBreadcrumbLocations(strings)
    // Add banner state
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState();
    const guestBannerHeight = guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0;

    let breadcrumb = [{ to: "/", name: strings.sideIconBar.lobbyMenuText },
    { to: "/", name: strings.receptionPage.receptionPageMyHeaderTitle },
    { to: locations[0], name: strings.myProfilePageContent.myProfileTitle }]


    return <div style={{ fontFamily: branding.font1, backgroundColor: "white" }}>
        <TopBar />
        <GuestUserBanner setRef={setGuestUserBannerRef} />
        <Breadcrumb breadcrumb={breadcrumb} />
        {
            myProfileMode === MyProfilePageMode.VIEW &&
            <ViewMyProfileLayout setMyProfileMode={setMyProfileMode} guestBannerHeight={guestBannerHeight} />
        }
        {
            myProfileMode === MyProfilePageMode.EDIT &&
            <EditMyProfilePageLayout setMyProfileMode={setMyProfileMode} guestBannerHeight={guestBannerHeight} />
        }
    </div>
}

export default MyProfilePageContent
