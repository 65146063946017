import { useRef, useState, useEffect, useCallback } from "react"
import { debounce } from "lodash"
import React from "react"
import styled from "styled-components"
import InfiniteScroll from "react-infinite-scroll-component"
import { Contact } from "../backendServices/Types"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { loadRelevantProfilesListData, BackendServiceError, ContactListResponse } from "../backendServices/BackendServices"
import Spinner from "react-bootstrap/esm/Spinner"
import { IconSearchSmall, IconClearSearchItem } from "../ui/Icons"
import { useLanguageState } from "../globalStates/LanguageState"
import { useRouteMatch } from "react-router-dom";
import { meetingPageRoute } from '../navigationArea/RoutePaths';
import branding from "../branding/branding"
import AvatarWithPresenceState from "../ui/AvatarWithPresenceState"
import "moment-timezone";

const SearchPersonInputRoot = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
`
const SearchPersonsRoot = styled.div`
    visibility: hidden;
    position: absolute;
    top: 36px;
    background-color: white;
    border: 1px solid grey;
    width: 100%;
    height: 260px;
    overflow: hidden;
    z-index: 10;

    -ms-overflow-style: none;
    scrollbar-width: none;    

    &::-webkit-scrollbar {
        display: none;
    }
`
const SearchPersonResultItem = styled.div<{ hideBorder?: boolean, pointerEnabled?: boolean, highlight?: boolean }>`
    border-bottom: ${props => props.hideBorder ? 'unset' : ''};
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};
    padding: 8px;
    background-color: ${props => props.highlight ? branding.calendarEntryModalPageContent.resultItemHover : "white"};
    pointer-events: ${props => props.pointerEnabled ? "auto" : "none"};
    cursor: pointer;
    display: flex;
    flex-flow: row;
    align-items: center;
    
    &.disabled {
        cursor: auto;
        color: gray;
        pointer-events: none;
    }
`
const SearchPersonResultTitle = styled.div`
    display: block;
    white-space: nowrap;
    padding-right: 10px;
    text-overflow: ellipsis;
    font-weight: bold;
`

const SearchPersonResultTitleCompany = styled.div`
    display: block;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
const PersonInput = styled.input`
    border: 0px;
    flex-grow: 1;
    margin-right: 4px;
    padding-top: 8px;
    background-color: #e8e8e8;
    &:focus {
        outline: none;
    }
`
const SearchIcon = styled.div`
    flex: 0 0 auto;
    width: 20px;
    height: 100%;
    margin-left: 5px;
    margin-right: 10px;
    color:#6a6a6a;
    & svg {
        width: 100%;
        height: 100%;
    }
`
const ClearIcon = styled.span`
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    margin-right: 7px;
    background-color: #e8e8e8
    & svg {
        width: 100%;
        height: 100%;
    }
`
interface ResultPerson extends Contact {
    disabled: boolean
}

interface PersonSearchInputProps {
    placeholder: string
    personClicked: (person: Contact) => void
    className?: string
    disableSearchIcon?: boolean
    disabledText?: string
    disableIds?: string[] // IDs of persons to be displayed as disabled in the result list
    excludeIds?: string[] // IDs of persons to be excluded from the result list
    calendarEntryModal?: boolean
    start?: Date
    end?: Date
    timezone?: string
}

const SearchPersonInput: React.FC<PersonSearchInputProps> = (props) => {
    const userState = useLoggedInState()

    const inputRef = useRef<HTMLInputElement>(null)
    const resultRowRef = useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = useState(false)
    const [page, setPage] = useState(0)
    const [resultPersons, setResultPersons] = useState<ResultPerson[]>([])
    const [clickedPersons, setClickedPersons] = useState<ResultPerson[]>([])
    const [searchStringValue, setSearchStringValue] = useState("")
    const [searchString, setSearchString] = useState("")
    const [hasNext, setHasNext] = useState<boolean>()
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [pointerEnabled, setPointerEnabled] = useState<boolean>(true);
    const [searchPersonRootHeight, setSearchPersonRootHeight] = useState<number>(0);
    const strings = useLanguageState().getStrings()
    let isMeetingPage = useRouteMatch(meetingPageRoute);

    useEffect(() => {

        const profileId = userState.user()?.profileId
        if (!profileId) return
        loadRelevantProfilesListData(profileId!, { page: page, itemsPerPage: 25, searchString: searchString })
            .then((data) => {
                if (searchString !== inputRef.current?.value) return
                if (!data) return
                if ((data as BackendServiceError).httpStatus) {
                    // TODO ERROR
                } else {
                    const contactListResp = data as ContactListResponse
                    const pageResultPersons: ResultPerson[] = []
                    contactListResp.contacts
                        .filter((contact) => !props.excludeIds?.includes(contact.sotUser.id))
                        .forEach(contact => {
                            const disabled = props.disableIds?.includes(contact.sotUser.id) || contact.sotUser.myConnectionStatus === "BLOCKED" || contact.sotUser.myConnectionStatus === "BLOCKING"

                            // Filter for guests, which people they can invite.
                            if (userState.user()?.type === "guest" && userState.user()?.invitingOrganization) {
                                if (contact.sotUser.organizations && contact.sotUser?.organizations[0] && contact.sotUser?.organizations[0].id === userState.user()?.invitingOrganization?.id) {
                                    pageResultPersons.push({ disabled: disabled, ...contact.sotUser })
                                }
                            }
                            else {
                                pageResultPersons.push({ disabled: disabled, ...contact.sotUser })
                            }
                        })

                    setHasNext(contactListResp.hasNextPage)
                    setResultPersons(page === 0 ? pageResultPersons : resultPersons.concat(pageResultPersons));
                }
            });
    }, [page, searchString]) // eslint-disable-line 


    useEffect(() => {
        const tempResultPersons = resultPersons.filter((contact) => !props.excludeIds?.includes(contact.id)).map(contact => {
            const newDisabled = props.disableIds?.includes(contact.id) ?? false
            const { disabled, ...rest } = contact
            return { disabled: newDisabled, ...rest }
        })
        setResultPersons(tempResultPersons);
    }, [props.excludeIds, props.disableIds]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (resultPersons.length < 11 && hasNext) {
            setPage(page + 1)
        }
    }, [resultPersons]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedIndex === null) {
            let personElement = document.getElementsByClassName("infiniteScrollPersonInput")[0]
            personElement.scrollTop = 0
        }
    }, [selectedIndex]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearchDebounced = useCallback(debounce((searchStr: string) => {
        setSearchString(searchStr)
        setPage(0)
        setSelectedIndex(null)
    }, 800), [])

    function onSearchStringChanged(e: React.FormEvent<HTMLInputElement>) {
        const searchStr = e.currentTarget.value
        setSearchStringValue(searchStr)
        setOpen(true)
        handleSearchDebounced(searchStr)
    }

    function onClickClearIcon() {
        setSearchStringValue("")
        setOpen(false)
        handleSearchDebounced("")
        setSelectedIndex(null)
    }

    function onExit() {
        setOpen(false)
        setPage(0)
        setSelectedIndex(null)
    }

    function onPersonClicked(person: ResultPerson) {
        if (person.disabled) {
            return
        }
        props.personClicked(person)
        setClickedPersons(clickedPersons.concat(person))
        setOpen(false)
        setPage(0)
        setSearchString("")
        setSearchStringValue("")
        setSelectedIndex(null)
    }

    function onKeyPressed(event: any, person: ResultPerson) {

        if (selectedIndex === null) {
            setSelectedIndex(0)
        }
        //on Enter pressed
        if (event.keyCode === 13 && person) {
            event.preventDefault()
            onPersonClicked(person)
        }
        //on arrowDown pressed
        if (event.keyCode === 40 && selectedIndex != null && selectedIndex < resultPersons.length - 1) {
            event.preventDefault()
            setPointerEnabled(false);
            setSelectedIndex(selectedIndex + 1)
            let personElement = document.getElementById("person" + (selectedIndex + 1).toString())
            //if(personElement && parentElement && (personElement?.offsetTop > parentElement?.offsetHeight + parentElement?.scrollTop))
            personElement?.scrollIntoView({ block: "nearest", behavior: "smooth" })
        }
        //on arrowUp pressed
        if (event.keyCode === 38 && selectedIndex != null && selectedIndex > 0) {
            event.preventDefault()
            setPointerEnabled(false);
            setSelectedIndex(selectedIndex - 1)
            let personElement = document.getElementById("person" + (selectedIndex - 1).toString())
            //if(personElement && parentElement && (personElement?.offsetTop < parentElement?.offsetHeight + parentElement?.scrollTop))
            personElement?.scrollIntoView({ block: "nearest", behavior: "smooth" })
        }
    }





    const resultItemsCount = Math.max(resultPersons.length, 1)

    const calculateTheDropdownHeight = () => {
        let searchPersonResultItemList = document.getElementsByClassName('searchPersonResultItem');
        let height = 0;
        for (let index = 0; index < searchPersonResultItemList.length; index++) {
            const element = searchPersonResultItemList[index];
            height += element.clientHeight;
        }
        setSearchPersonRootHeight((height > 400 ? 400 : height) || 53) // Max height is 400 and if there are no results height should be 53 to show the "No results found" row
    }
    useEffect(() => {
        if (isOpen)
            calculateTheDropdownHeight();
    }, [resultPersons.length, isOpen])


    return <SearchPersonInputRoot className={props.className}>
        {!props.disableSearchIcon && <SearchIcon>{IconSearchSmall({ fill: branding.sideIconBar.sideIconColorDark })}</SearchIcon>}
        <PersonInput ref={inputRef} placeholder={props.placeholder} onChange={onSearchStringChanged} onClick={() => setOpen(true)} onBlur={onExit} value={searchStringValue} onKeyDown={(event) => onKeyPressed(event, resultPersons[selectedIndex!])} />
        {!props.disableSearchIcon && <ClearIcon onClick={onClickClearIcon} style={{ 'color': isMeetingPage ? 'white' : branding.mainInfoColor ?? '#000', visibility: searchStringValue !== "" ? 'visible' : 'hidden' }}>{IconClearSearchItem({ fill: isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}</ClearIcon>}
        <SearchPersonsRoot id="searchPersons" style={{ visibility: isOpen ? 'visible' : 'hidden', height: resultItemsCount < 12 ? searchPersonRootHeight : 260 }} onPointerMove={(event) => { setPointerEnabled(true) }}>
            <InfiniteScroll
                style={{ overflowX: "hidden" }}
                className="infiniteScrollPersonInput"
                dataLength={resultItemsCount}
                next={() => setPage(page + 1)}
                hasMore={hasNext as boolean}
                scrollableTarget="searchPersons"
                scrollThreshold="100%"
                height={260}
                loader={<SearchPersonResultItem style={{ justifyContent: "center" }}><Spinner animation="border" /></SearchPersonResultItem>}
            >
                {resultPersons.map((person, index) => (
                    <SearchPersonResultItem id={"person" + index} hideBorder={(resultPersons.length - 1) === index} highlight={index === selectedIndex} pointerEnabled={pointerEnabled} tabIndex={index} ref={resultRowRef} key={index} onMouseEnter={(event) => setSelectedIndex(index)} onMouseDown={(event) => { event.preventDefault(); onPersonClicked(person) }} className={[person.disabled ? "disabled" : "", "searchPersonResultItem"].join(" ")} >
                        <AvatarWithPresenceState badgeSize={8} showAvatarBadge={true} badgeRight={20} badgeTop={23} userId={person.id} avatarSize={36} content={{ pictureUrl: person.logoUrl, alt: person.firstName + " " + person.lastName }} ></AvatarWithPresenceState>
                        <SearchPersonTextRoot>
                            <SearchPersonTitleContainer calendarEntryModal={props.calendarEntryModal}>
                                <SearchPersonResultTitle>{[person.firstName, person.lastName].join(" ")}</SearchPersonResultTitle>
                                {person.position ? "|" : ""}
                                <SearchPersonResultTitleCompany>
                                    {person.position}
                                    {(person.position && person.company) && <span> / </span>}
                                    {person.company}
                                </SearchPersonResultTitleCompany>
                            </SearchPersonTitleContainer>
                        </SearchPersonTextRoot>
                    </SearchPersonResultItem>
                ))}
                {resultPersons.length === 0 && <SearchPersonResultItem key="mt" className="disabled"
                    onMouseDown={(event) => { event.preventDefault() }}>
                    {strings.chatBranding.noResultFoundText}</SearchPersonResultItem>}
            </InfiniteScroll>
        </SearchPersonsRoot>
    </SearchPersonInputRoot>
}

const SearchPersonTextRoot = styled.div`
    display: flex;
    flex-flow: column;
`
const SearchPersonTitleContainer = styled.div<{ calendarEntryModal?: boolean }>`
    display: flex;
    flex-flow: row;
    line-height: 23px;
    font-size: ${props => props.calendarEntryModal ? "15px" : "11px"};
    margin-bottom: 2px;
    font-weight: bold;
    width: ${props => props.calendarEntryModal ? "530px" : "220px"};
    
`
export default SearchPersonInput
