import { homePageRoute, exhibitorsPageRoute, programPageRoute, conferencePageRoute, networkingPageRoute, sponsorsPageRoute, experiencesPageRoute, couponsPageRoute, pressMediaPageRoute, businessAreaPageRoute } from "../RoutePaths";
import { IconHome, IconOrganization, IconProgram, IconMeetings, IconNetworking, IconSponsor, IconExperiences, IconCoupon, IconMyFair, IconPressMedia, IconBusinessArea } from "../../ui/Icons";
import branding from "../../branding/branding";

//side icon bar enumeration for IDs
export enum SideIconBarItemIds {
    "MY_FAVOURITES" = "MY_FAVOURITES",
    "LOBBY" = "LOBBY",
    "SHOWFLOOR" = "SHOWFLOOR",
    "PROGRAM" = "PROGRAM",
    "VIRTUAL_CAFES" = "VIRTUAL_CAFES",
    "NETWORKING" = "NETWORKING",
    "PARTNERS_SPONSORS" = "PARTNERS_SPONSORS",
    "EXPERIENCES" = "EXPERIENCES",
    "GOODIE_BAG" = "GOODIE_BAG",
    "PRESS_MEDIA" = "PRESS_MEDIA",
    "BUSINESS_AREA" = "BUSINESS_AREA"
}

//side icon bar enumeration for keys
export enum SideIconBarItemKeys {
    "home" = "home",
    "organization" = "organization",
    "program" = "program",
    "meetings" = "meetings",
    "network" = "network",
    "sponsor" = "sponsor",
    "experiences" = "experiences",
    "coupon" = "coupon",
    "press_media" = "press_media",
    "business" = "business"
}

interface SideBarConfigType {
    id: SideIconBarItemIds
    key: SideIconBarItemKeys
    path: string
    icon: any
    title: string
    lobbyCheck: boolean
    checkString?: string
}

function checkActiveItem(key: string, lobbyCheck: boolean, checkString: string, currentItem: string, myToggle: string): boolean {

    if (lobbyCheck) {
        return (currentItem === key && myToggle === checkString)
    }

    return currentItem === key
}


//method which provides side icon bar items with configuration
export const SideBarConfig = (strings: any, currentItem: string, isMeetingPage: boolean, myToggle: string): Array<SideBarConfigType> => {

    function isActiveItem(key: string, lobbyCheck?: boolean, checkString?: string): boolean {
        return checkActiveItem(key, lobbyCheck ?? false, checkString ?? "", currentItem, myToggle)
    }

    function getIconFill(active: boolean): string {
        let color: string = isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor

        if (!active) {
            color = branding.sideIconBar.inactiveItemColor
        }

        return color
    }

    function getIconStroke(active: boolean): string {
        let color: string = isMeetingPage ? branding.sideIconBar.sideIconColorDark : branding.sideIconBar.sideIconColorLight


        return color
    }


    return [
        {
            id: SideIconBarItemIds.MY_FAVOURITES,
            key: SideIconBarItemKeys.home,
            path: homePageRoute,
            icon: IconMyFair({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.home, true, strings.receptionPage.receptionPageMyHeaderTitle)) }),
            title: strings.receptionPage.receptionPageMyHeaderTitle,
            lobbyCheck: true,
            checkString: strings.receptionPage.receptionPageMyHeaderTitle
        },
        {
            id: SideIconBarItemIds.LOBBY,
            key: SideIconBarItemKeys.home,
            path: homePageRoute,
            icon: IconHome({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.home, true, SideIconBarItemIds.LOBBY)) }),
            title: strings.sideIconBar.lobbyMenuText,
            lobbyCheck: true,
            checkString: SideIconBarItemIds.LOBBY
        },
        {
            id: SideIconBarItemIds.SHOWFLOOR,
            key: SideIconBarItemKeys.organization,
            path: exhibitorsPageRoute,
            icon: IconOrganization({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.organization)) }),
            title: strings.sideIconBar.showfloorMenuText,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.PROGRAM,
            key: SideIconBarItemKeys.program,
            path: programPageRoute,
            icon: IconProgram({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.program)), stroke: getIconStroke(isActiveItem(SideIconBarItemKeys.program)) }),
            title: strings.sideIconBar.programMenuText,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.VIRTUAL_CAFES,
            key: SideIconBarItemKeys.meetings,
            path: conferencePageRoute,
            icon: IconMeetings({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.meetings)), stroke: getIconStroke(isActiveItem(SideIconBarItemKeys.meetings)) }),
            title: strings.sideIconBar.virtualcafeMenuText,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.NETWORKING,
            key: SideIconBarItemKeys.network,
            path: networkingPageRoute,
            icon: IconNetworking({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.network)) }),
            title: strings.sideIconBar.networkingMenuText,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.PARTNERS_SPONSORS,
            key: SideIconBarItemKeys.sponsor,
            path: sponsorsPageRoute,
            icon: IconSponsor({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.sponsor)) }),
            title: strings.sideIconBar.mediapartnersMenuText,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.EXPERIENCES,
            key: SideIconBarItemKeys.experiences,
            path: experiencesPageRoute,
            icon: IconExperiences({ stroke: getIconStroke(isActiveItem(SideIconBarItemKeys.experiences)) }),
            title: strings.sideIconBar.experiencesMenuText,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.GOODIE_BAG,
            key: SideIconBarItemKeys.coupon,
            path: couponsPageRoute,
            icon: IconCoupon({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.coupon)) }),
            title: strings.sideIconBar.goodiebagMenuText,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.PRESS_MEDIA,
            key: SideIconBarItemKeys.press_media,
            path: pressMediaPageRoute,
            icon: IconPressMedia({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.press_media)) }),
            title: strings.sideIconBar.pressMediaMenuText,
            lobbyCheck: false
        },
        {
            id: SideIconBarItemIds.BUSINESS_AREA,
            key: SideIconBarItemKeys.business,
            path: businessAreaPageRoute,
            icon: IconBusinessArea({ fill: getIconFill(isActiveItem(SideIconBarItemKeys.business)) }),
            title: strings.sideIconBar.businessAreaMenuText,
            lobbyCheck: false 
        }
    ]
}