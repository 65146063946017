import React, { useEffect, useState } from 'react'
import { useOnlineUsers } from '../../tracking/LoungeUsers'
import styled from 'styled-components'
import branding from "../../branding/branding";
import { useHistory, NavLink } from 'react-router-dom';
import { DetailNavLink } from '../detailPages/DetailNavLink';
import CrsPersons from '../../ui/CrsPersons';
import { Person } from '../../backendServices/Types';
import { trackVisit, UserOrganizationVisitType, UserRestrictedAreaAccess, RestrictedAreaTypes } from '../../backendServices/BackendServices';
import { useLoggedInState } from '../../globalStates/LoggedInUser';
import { useLanguageState } from '../../globalStates/LanguageState';
import { getUrlForMeeting } from '../../conference/context/ChimeContext';
import RequestAccessModal from '../../ui/RequestAccessModal';
import GuestModal from "../../ui/GuestModal";
import { isExplorationOrPostEventPhase } from "../../EventPhaseChecker"
import { hasAccessToOrganization } from '../../Authorization';
import useWindowDimensions from '../../ui/WindowDimensionsHook';
import { getIamPartOf } from '../../globalStates/IAmPortOf';
import queryString from 'query-string'

const queryParams: any = queryString.parse(window.location.search)

const Tile = styled(NavLink) <{ $pictureUrl?: string, margin?: string, disableOverlayGradient?: boolean }>`
    position: relative;
    display: flex;
    flex-flow: column;
    width: 500px;
    height: 280px;
    background: ${props => [
        props.disableOverlayGradient ? null : `linear-gradient(117.38deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.28) 100%)`,
        props.$pictureUrl ? `url("${props.$pictureUrl}");` : null
    ].filter(Boolean).join(",")};
    color: ${props => props.$pictureUrl ? "#fff" : "#000"};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px;
    margin:${props => props.margin ?? "20px 20px 20px 0px"};
    color: white !important;
    cursor: ${isExplorationOrPostEventPhase && !(getIamPartOf(queryParams) === "onboarding") ? "default" : "pointer"};

    &.private {
        cursor: not-allowed;
    }

    &:hover, &:active, &:link, &:focus {
        text-decoration: none;
        color: #fff;
    }

    .pers-row {
        div{
            width:30px;
            height: 30px;
            margin-right: 5px;
            font-size: 14px;
            img {
                width:30px;
                height: 30px;
            }
        }
    }
    & h2 {
        font-family: ${branding.font2};
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000;
        opacity: 0.2;
        z-index: 1;
    }
    &>*{
        z-index: 2;
    }
    .btn-reqest-access {
        font-family: ${branding.font1};
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 17px;
        border-radius: 1rem;
        &.focus, &:focus,
        &.hover, &:hover,
        &.active, &:active {
            box-shadow: none !important;
        }
    }

    .bottom-overlay { 
        position: absolute;
        height: 30%;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
    }
`

const MeetingRoomGroupTitle = styled.h2`
    white-space: pre-line;
    font: ${branding.meetingRoomGroupsTitleFontStyle ? branding.meetingRoomGroupsTitleFontStyle : "normal bold 18px/23px"} ${branding.font1} !important;
`

const HostedBy = styled.div`
    font: ${branding.meetingRoomGroupsHosterFontStyle ? branding.meetingRoomGroupsHosterFontStyle : "normal 300 10px/12px"} ${branding.font1};
    & div {
        display: inline;
        text-decoration: underline;
    }
`

const MeetingRoomGroupDescription = styled.div`
    margin-top: auto;
    white-space: pre-line;
    width: 260px;
    font: ${branding.meetingRoomGroupsDescriptionFontStyle ? branding.meetingRoomGroupsDescriptionFontStyle : "normal 300 12px/17px"} ${branding.font1};
`

/* #region  Meeting Room Group Component */
const ActionButtonDiv = styled.div<{ static?: boolean }>`
    display: flex;
    width: 140px;
    height: 30px;
    position: absolute;
    right: 20px;
    border: 1px solid white;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: white;
    text-decoration: none;
    cursor: ${props => props.static ? "default" : "pointer"};
    font-size: ${branding.receptionPage.virtualCafeAccessButtonFontSize ?? "12px"};
    line-height: 17px;
    font-family: ${branding.font1};


    :hover {
        background-color: ${props => props.static ? "none" : "rgba(0,0,0,0.2)"};
        color: ${props => props.static ? "black" : `white`};
        text-decoration: none;
    }
`
const MoreContactsText = styled.p`
    margin-bottom: 0rem;
`;
export interface MeetingRoomGroupProps {
    id: string
    isPrivate: boolean
    title: string
    titleVisible: boolean
    organizationName?: string
    organizationId?: string
    description?: string
    pictureUrl?: string
    accessStatus?: string
    source: 'LOBBY' | 'ORG_DETAIL' | 'VC'
    setRefresh?: () => void
    margin?: string
    bottomExtraOverlay?: string
}

const PersonsRowWrapper = styled.div`
    .persons-row > div > div:last-child > div {
        position: absolute;
    }

    /* hack for CrsPersons */
  @media (max-width: 1400px) {
    .persons-row img {
      width: 36px;
      height: 36px;
    }
    .persons-row > div > div:last-child > div {
      position: absolute;
      width: 30px;
      height: 30px;
    }
  }
  @media (max-width: 1000px) {
    .persons-row img {
      width: 30px;
      height: 30px;
    }
    .persons-row > div > div:last-child > div {
      position: absolute;
      width: 30px;
      height: 30px;
    }
  }
`

const MeetingRoomGroup: React.FunctionComponent<MeetingRoomGroupProps> = (props) => {
    const onlineUsers = useOnlineUsers()
    const persons = onlineUsers.getUsersInRoomOrLounge(props.id).map((x: any) => { return { logoUrl: x.user.pictureUrl, id: x.id, name: x.user.name } })
    const history = useHistory();
    const loggedInUserId = useLoggedInState().user()?.profileId
    const loggedUser = useLoggedInState().user()
    const visitType: UserOrganizationVisitType = props.source === 'ORG_DETAIL' ? 'VC#DETAIL' : props.source === 'LOBBY' ? 'VC#LOBBY' : 'VC#VC'
    const languageState = useLanguageState();
    const strings = languageState.getStrings();
    const [userRequestAccessLoaded, setUserRequestAccessLoaded] = useState<boolean>(false)
    const [userVirtualCafeAccess, setUserVirtualCafeAccess] = useState<UserRestrictedAreaAccess>()
    //const [error, setError] = useState<BackendServiceError | string | null>(null)
    const [showRequestAccessModal, setShowRequestAccessModal] = useState<boolean>(false)
    const [showGuestModal, setShowGuestModal] = useState<boolean>(false)
    const queryParams: any = queryString.parse(window.location.search)

    function checkStaffAccess(): boolean {
        return ((loggedUser?.organizations?.find(o => o.id === props.organizationId) !== undefined))!
    }

    let actionButtonField = null

    const onRequestAccessClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        if (!loggedUser || !hasAccessToOrganization(loggedUser, props.organizationId!)) {
            setShowGuestModal(true)
            return
        }

        setShowRequestAccessModal(true)
    }

    function setActionButtonField() {
        if (!props.isPrivate || (props.accessStatus && props.accessStatus === "GRANTED") || userVirtualCafeAccess?.status === "GRANTED" || checkStaffAccess()) {
            return
        }
        if (props.accessStatus && !userRequestAccessLoaded) {
            if (props.accessStatus === "REQUESTED") {
                actionButtonField = (<ActionButtonDiv static={true}>
                    <MoreContactsText style={{ zIndex: 3 }}>{strings.globalTexts.requestSent}</MoreContactsText></ActionButtonDiv>)
            } else {
                actionButtonField = (<ActionButtonDiv onClick={onRequestAccessClick}>
                    <MoreContactsText style={{ zIndex: 3 }}>{strings.globalTexts.requestAccess}</MoreContactsText></ActionButtonDiv>)
            }
        }
        else if (userRequestAccessLoaded === true && userVirtualCafeAccess) {
            if (userVirtualCafeAccess && userVirtualCafeAccess.status === "REQUESTED") {
                actionButtonField = (<ActionButtonDiv static={true}>
                    <MoreContactsText style={{ zIndex: 3 }}>{strings.globalTexts.requestSent}</MoreContactsText></ActionButtonDiv>)
            } else {
                actionButtonField = (<ActionButtonDiv onClick={onRequestAccessClick}>
                    <MoreContactsText style={{ zIndex: 3 }}>{strings.globalTexts.requestAccess}</MoreContactsText></ActionButtonDiv>)
            }
        }
    }
    setActionButtonField()

    const onEnterCafe = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        if (isExplorationOrPostEventPhase && !(getIamPartOf(queryParams) === "onboarding")) 
            return

        if (loggedUser && props.organizationId && !hasAccessToOrganization(loggedUser, props.organizationId)) {
            setShowGuestModal(true)
            return
        }

        if (!props.isPrivate || (props.accessStatus && props.accessStatus === "GRANTED") || userVirtualCafeAccess?.status === "GRANTED" || checkStaffAccess()) {
            history.push("/meetings/" + props.id);
            if (loggedInUserId && props.organizationId) {
                trackVisit(loggedInUserId, props.organizationId, visitType, props.id)
            }
        }
        if (props.setRefresh)
            props.setRefresh()
    }

    useEffect(() => {

        onlineUsers.subscribe()
        return () => onlineUsers.unsubscribe()
    }, [props.id]) //eslint-disable-line

    useEffect(() => {
        setActionButtonField()
        // eslint-disable-next-line
    }, [userVirtualCafeAccess, userRequestAccessLoaded])

    return <>
        <Tile to={`/meetings/${props.id}`} margin={props.margin} onClick={onEnterCafe} $pictureUrl={props.pictureUrl} disableOverlayGradient={branding.meetingRoomGroupsDisablePictureOverlay} className={props.isPrivate && (props.accessStatus && props.accessStatus !== "GRANTED") && !checkStaffAccess() ? "private" : undefined} style={{ marginLeft: "5px", marginRight: "5px" }}>
            {props.bottomExtraOverlay && <div className={'bottom-overlay'}></div>}
            {showRequestAccessModal &&
                <RequestAccessModal
                    onUserRequestLoadedSet={setUserRequestAccessLoaded}
                    onUserRestrictedAreaAccessSet={setUserVirtualCafeAccess}
                    restrictedAreaId={props.id}
                    restrictedAreaType={RestrictedAreaTypes.VirtualCafe}
                    onHide={() => { setShowRequestAccessModal(false) }}>
                </RequestAccessModal>}
            <div className="row">
                <div className={props.isPrivate ? "col-7" : "col-12"}>
                    <MeetingRoomGroupTitle>{props.titleVisible && props.title}</MeetingRoomGroupTitle>
                    {props.organizationName && props.organizationId && props.source !== 'ORG_DETAIL' && (
                        <HostedBy>
                            {strings.globalTexts.hostedByText}{" "}
                            <DetailNavLink id={props.organizationId} name={props.organizationName} type="organization" source="VC">
                                {props.organizationName}
                            </DetailNavLink>
                        </HostedBy>
                    )}
                </div>
                <div>
                    {actionButtonField}
                </div>
            </div>
            <PersonsRowWrapper style={{ marginTop: "auto" }} className="row align-items-center">
                <div className={(persons.length > 3 ? "col-md-6" : "col-md-8") + " col-12"}>{props.description && <MeetingRoomGroupDescription style={{ width: "unset" }}>{props.description}</MeetingRoomGroupDescription>}</div>
                <div className={(persons.length > 3 ? "col-md-6" : "col-md-4") + " col-12 mt-3 mt-md-0 align-self-end persons-row"}>
                    <CrsPersons justifycontent="flex-end" layoutType={1} showModalPopup={false} persons={persons as Person[]} personsToShow={4} width={"30px"} height={"30px"}></CrsPersons>
                </div>
            </PersonsRowWrapper>
        </Tile>
        {showGuestModal && <GuestModal close={() => setShowGuestModal(false)} />}
    </>
}

export default MeetingRoomGroup
/* #endregion */

/* #region  Meeting Room Component */
interface MeetingRoomProps {
    id: string
    title: string
    titleVisible: boolean
    description?: string
    pictureUrl?: string
    groupId: string
    organizationId: string
    setRefresh?: () => void
}
export const MeetingRoom: React.FunctionComponent<MeetingRoomProps> = (props) => {
    const room = props.groupId + "/" + props.id
    const onlineUsers = useOnlineUsers()
    const persons = onlineUsers.getUsersInRoomOrLounge(room).map((x: any) => { return { logoUrl: x.user.pictureUrl, name: x.user.name } })
    const history = useHistory();
    const loggedInUser = useLoggedInState().user()
    const windowSize = useWindowDimensions();


    useEffect(() => {
        onlineUsers.subscribe()
        return () => onlineUsers.unsubscribe()
    }, [room]) //eslint-disable-line

    const onEnterRoom = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        history.push(getUrlForMeeting(props.id, "virtualCafe"));
        if (loggedInUser && props.organizationId) {
            trackVisit(loggedInUser.profileId, props.organizationId, 'VCROOM', props.groupId + '/' + props.id)
        }
        if (props.setRefresh)
            props.setRefresh()
    }


    let customClassName = "d-flex flex-row justify-content-between align-items-center w-100"

    if (windowSize.width <= 1800)
        customClassName = "d-flex flex-column justify-content-center align-items-start w-100"

    return <Tile to={getUrlForMeeting(props.id, "virtualCafe")} className={'tile-inside-vg-room'} onClick={onEnterRoom} $pictureUrl={props.pictureUrl} disableOverlayGradient={branding.meetingRoomGroupsDisablePictureOverlay}>
        <div className="d-flex justify-content-between flex-column align-items-start h-100">
            {/* <h2>{props.title}</h2> TODO h2 makes all CAPS */}
            <MeetingRoomGroupTitle>{props.titleVisible && props.title}</MeetingRoomGroupTitle>
            <div className={customClassName}>
                <div className="mb-2">
                    {props.description && <MeetingRoomGroupDescription>{props.description}</MeetingRoomGroupDescription>}
                </div>
                <div className="crs-persons-parent">
                    <CrsPersons layoutType={1} showModalPopup={false} persons={persons as Person[]} personsToShow={4}></CrsPersons>
                </div>
            </div>
        </div>
    </Tile>
}

/* #endregion */