import styled from "styled-components"
import React, { useEffect, useState, useRef } from "react"
import { useParams, useHistory } from "react-router-dom"
import CenteredLoader from "../../ui/CenteredLoader"
import { Company, ShareTargetType } from "../../backendServices/Types"
import { loadExhibitorData, BackendServiceError, trackVisit, listAttendeeInfos, AttendeeData, getUserOrgaInfo, syncFavorites, } from "../../backendServices/BackendServices"
import branding, { LocalizedString } from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import TopBar from '../../navigationArea/TopBar';
import Breadcrumb from '../../navigationArea/Breadcrumb';
import { IconCameraJoin, IconShare, IconMeetingSchedule, IconCall, IconBackoffice, IconBookmarkToggle, IconBookmarkFilled, IconDownloadVCard } from "../../ui/Icons";
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import { Row, Col, Container } from "react-bootstrap"
import { buildDetailLink } from "./DetailNavLink"
import BackendError from "../../ui/BackendError"
import { useLoggedInState, User } from "../../globalStates/LoggedInUser"
import { useCalendarEntryModal } from "../../ui/CalendarEntryModal"
import SayHelloModal from "../../ui/SayHelloModal"
import { useRecommendOrganizationModal } from "../../ui/RecommendOrganizationModal"
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';
import { usePrivacyPolicyModal } from "../../ui/PrivacyPolicyModal"
import GuestModal from "../../ui/GuestModal"
import { useAppState } from "../../globalStates/AppState"
import { useChimeContext, getExternalMeetingId } from "../../conference/context/ChimeContext"
import { useMeetingContext } from "../../conference/context/MeetingContext"
import moment from "moment"
import GuestUserBanner from "../guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../guestUserBanner/GuestUserBannerSharedState"
import { isExplorationOrPostEventPhase, isLivePhase } from "../../EventPhaseChecker"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { useFavoriteState } from "../../globalStates/Favorites"
import { BasisPremiumType, getBasisPremiumConfig } from "../../branding/BasisPremiumBranding"
import { VideoModal } from "./OrgDetailHeaderVideo"
import { exhibitorsPageRoute } from "../../navigationArea/RoutePaths"
import DetailPageSections, { DetailSection, InfoContentOrderType } from "./components/DetailPageSections"
import { aspectRatio, aspectRatioShrinked, DetailsRoot, ScrollContainerRoot } from "./components/StyledComponents"
import { hasAccessToOrganization } from "../../Authorization";
import { saveExhibitorVCard } from "../../communicationArea/VCard"
import { SocialMediaType } from "../myprofile/EditMyProfileLayout"

import queryString from 'query-string'
import { getIamPartOf } from "../../globalStates/IAmPortOf"

export interface OrganizationDetailPageContentBranding {
    navbarTitle: LocalizedString
    sectionLineContactPerson: LocalizedString
    sectionLineCategories: LocalizedString
    sectionLineProductDescription: LocalizedString
    buttonShowMore: LocalizedString
    buttonCollapse: LocalizedString
    joinShowroom: LocalizedString
    requestMeeting: LocalizedString
    registerInterest: LocalizedString
    recommend: LocalizedString
    bookmark: LocalizedString
    backoffice: LocalizedString
    connectWithUs: LocalizedString
    talkWithTeamBefString: LocalizedString
    talkWithTeamAftString: LocalizedString
    joinButton: LocalizedString
    liveSessionsTitle: LocalizedString
    upcomingSessionsTitle: LocalizedString
    pastSessionsTitle: LocalizedString
    virtualCafesTitle: LocalizedString
    mediaTitle: LocalizedString
    linksTitle: LocalizedString
    joinNowButton: LocalizedString
    showFullScreen: LocalizedString
    bookmarkPerson: LocalizedString
    foundingYearTitle: LocalizedString
    companySizeTitle: LocalizedString
    industryTitle: LocalizedString
    explorationPhasePlaceholder: LocalizedString
    postPhasePlaceholder: LocalizedString
    mediaSliderPrimaryColor: string
    iconColor: string
    companyInformationTitle: LocalizedString
    companyContactToTitle: LocalizedString
    orgaDetailPageMainContentOrder: Array<DetailSection>
    orgaDetailPageInfoSectionOrder: Array<InfoContentOrderType>
    defaultHeaderBasisPremiumTypes: Array<string>
    companyLinksFontWeight: string
    categoriesSectionTitle: LocalizedString
    coExhibitorsSectionTitle: LocalizedString
    parentSectionTitle: LocalizedString
    removeExpoShowroomDuringPostEvent: boolean

    blockedStaffIconColor: string

    numberOfProductsNone: number
    numberOfProductsBasic: number
    numberOfProductsStandard: number
    numberOfProductsPremium: number | string

    numberOfTrademarksNone: number
    numberOfTrademarksBasic: number
    numberOfTrademarksStandard: number
    numberOfTrademarksPremium: number | string

    vCardNoteTemplate: LocalizedString
    vCardDownloadAvailable: boolean

    availableSocialMedias: SocialMediaType[]
}


/*********************************************************************************************
 * site assembly
**********************************************************************************************/
const HeaderSticky = styled.div<{ paddingBottom: string }>`
    position: relative; 
    padding-bottom: ${props => props.paddingBottom};
    transition: .5s;

    &::after{
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;  
        right: 0;
        background: ${props => props.paddingBottom === aspectRatioShrinked ?
        "linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, .5))" :
        "linear-gradient(180deg,  rgba(255, 255, 255, .4) 70%, rgba(0, 0, 0, .6) 85%)"};
        opacity: .5;
        pointer-events: none;
        transition: opacity .5s;

        
        @media(max-width: 1700px){
            background: ${props => props.paddingBottom === aspectRatioShrinked ?
        "linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, .5))" :
        "linear-gradient(180deg,rgba(255, 255, 255, .4) 70%, rgba(0, 0, 0, .6) 85%)"};
        }

        @media(max-width: 1500px){
            background: ${props => props.paddingBottom === aspectRatioShrinked ?
        "linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, .5))" :
        "linear-gradient(180deg, rgba(255, 255, 255, .4) 70%, rgba(0, 0, 0, .6) 85%)"};
        }

        @media(max-width: 1300px){
            background: ${props => props.paddingBottom === aspectRatioShrinked ?
        "linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, .5))" :
        "linear-gradient(180deg, rgba(255, 255, 255, .4) 70%, rgba(0, 0, 0, .6) 85%)"};
        }

        @media(max-width: 1300px){
            background: linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, .5));
        }
        
    }
`

const HeaderRoot = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0; 
    left: 0;
    
    & img { 
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left top;
    }
`


/* #region  Company header */
const CompanyHeaderContainer = styled(Container)`
    z-index: 5;
`

const CompanyHeaderRoot = styled.div`
    position: absolute;
    bottom: 10px; 
    width: 70%; 
    left: 15%; 
    z-index: 5;
`

const CompanyHeaderRow = styled(Row)`
    justify-content: center; 
`

const CompanyHeaderCol = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: ${branding.font1};
    flex-direction: column;
    cursor: pointer;
`

const HeaderItemsTitle = styled.div`
    font-size: 16px;
    text-align: center;
    font-family: ${branding.font1};
    @media(max-width: 1600px) {
       font-size: 14px;
    }

    @media(max-width: 1400px) {
       font-size: 12px;
    }

    @media(max-width: 1200px) {
       font-size: 10px;
    }
`

const HeaderIconDiv = styled.div`
    cursor: pointer;
`

// oetker
interface CompanyHeaderProps {
    isStaff: boolean
    organizationId: string
    organizationName: string
    exhibitor?: Company
    isBookmarked?: boolean
}

function CompanyHeader(props: CompanyHeaderProps) {
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    const history = useHistory();
    const strings = useLanguageState().getStrings()
    const chime = useChimeContext()
    const meeting = useMeetingContext()
    const [showRequestOrganizationModal, setShowRequestOrganizationModal] = useState<boolean>(false)
    const { showRecommendOrganizationModal, OrganizationModal } = useRecommendOrganizationModal()
    const { showCalendarEntryModal, CalendarModal } = useCalendarEntryModal()
    const [showGuestModal, setShowGuestModal] = useState(false)
    const queryParams: any = queryString.parse(window.location.search)
    //isBookmarked
    const userState = useLoggedInState()
    const loggedInUserId = userState.user()?.profileId;
    const favorites = useFavoriteState()

    const language = useLanguageState().getLanguage()
    let config = getBasisPremiumConfig(props.exhibitor?.basisPremium! as BasisPremiumType);
    const updateBookmarkedStatus = () => {
        if (props.organizationId) {
            if (loggedInUserId) {
                favorites.toggle("organization", props.organizationId)
                syncFavorites({
                    profileId: loggedInUserId,
                    body: {
                        currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        lastSyncTime: favorites.getLastSyncTime(),
                        changedFavorites: [{
                            id: props.organizationId,
                            kind: ("organization").toUpperCase(),
                            deleted: favorites.is("organization", props.organizationId) ? false : true,
                            lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }]
                    }
                }).then((resp) => {
                    favorites.setLastSyncTime(new Date())
                    //setIsBookmarked(favorites.is("organization", props.organizationId))
                }).catch((e: { message: React.SetStateAction<string> }) => {
                    // syncFavorites failed, logged in BackendServices
                })
            }
        }
    }

    function getVCardNote() {
        var date = moment()

        var template = strings.organizationDetailPageContent.vCardNoteTemplate
        var contentString = template.split('{$eventGuideName}').join(strings.configuration.ogTitle)
        contentString = contentString.split('{$date}').join(language === "en" ? date.format("MMMM DD, YYYY") : date.format("DD. MMMM YYYY"))

        return contentString
    }

    function doVCard(organization: Company) {
        saveExhibitorVCard(organization, getVCardNote())
    }

    return (
        <CompanyHeaderContainer>
            <CompanyHeaderRow>
                {hasAvailableStaff(props.exhibitor) && (!isExplorationOrPostEventPhase || (getIamPartOf(queryParams) === "onboarding")) && config.expoShowroomEnabled &&
                    <CompanyHeaderCol xs={2} style={{ maxWidth: "13%" }} className="mt-4" onClick={() => {
                        if (hasAccessToOrganization(userState.user()!, props.exhibitor!.id))
                            showPrivacyPolicyModal(props.organizationId, (accepted) => {
                                joinShowroom(props.exhibitor as Company, userState.user()!, chime, meeting)
                            })
                        else
                            setShowGuestModal(true)
                    }}>
                        <HeaderIconDiv className="mb-1 h-50">{IconCameraJoin({ stroke: "#FFF", fill: "#FFF" })}</HeaderIconDiv>
                        <HeaderItemsTitle>{strings.organizationDetailPageContent.joinShowroom}</HeaderItemsTitle>
                    </CompanyHeaderCol>}
                {props.exhibitor?.basisPremium !== BasisPremiumType.NONE && <CompanyHeaderCol xs={2} className="mt-4" onClick={() => {
                    if (hasAccessToOrganization(userState.user()!, props.exhibitor!.id)) {
                        showPrivacyPolicyModal(props.organizationId, (accepted) => {
                            trackVisit(loggedInUserId!, props.organizationId, "CALENDARENTRY#CLICK")
                            showCalendarEntryModal(props.organizationId, [])
                        })
                    } else
                        setShowGuestModal(true)
                }}
                >
                    <HeaderIconDiv className="mb-1 h-50">{IconMeetingSchedule({ stroke: "#FFF", fill: "#FFF" })}</HeaderIconDiv>
                    <HeaderItemsTitle>{strings.organizationDetailPageContent.requestMeeting}</HeaderItemsTitle>
                </CompanyHeaderCol>} <CompanyHeaderCol xs={2} style={{ maxWidth: "13%" }} className="mt-4" onClick={() => {
                    if (hasAccessToOrganization(userState.user()!, props.exhibitor!.id)) {
                        showPrivacyPolicyModal(props.organizationId, (accepted) => {
                            trackVisit(loggedInUserId!, props.organizationId, "INTEREST#CLICK")
                            setShowRequestOrganizationModal(true)
                        })
                    } else
                        setShowGuestModal(true)
                }}>
                    <HeaderIconDiv className="mb-1 h-50">{IconCall({ stroke: "#FFF", fill: "#FFF" })}</HeaderIconDiv>
                    <HeaderItemsTitle>{strings.organizationDetailPageContent.registerInterest}</HeaderItemsTitle>
                </CompanyHeaderCol>
                <CompanyHeaderCol xs={2} style={{ maxWidth: "13%" }} className="mt-4" onClick={() => showRecommendOrganizationModal(ShareTargetType.ORGANIZATION, props.organizationId, buildDetailLink(props.organizationId, props.organizationName, 'organization'))} >
                    <HeaderIconDiv className="mb-1 h-50"> {IconShare({ stroke: "#FFF", fill: "#FFF" })}</HeaderIconDiv>
                    <HeaderItemsTitle>{strings.organizationDetailPageContent.recommend}</HeaderItemsTitle>
                </CompanyHeaderCol>
                <CompanyHeaderCol xs={2} style={{ maxWidth: "13%" }} className="mt-4" onClick={() => updateBookmarkedStatus()}>
                    <HeaderIconDiv className="mb-1 h-50">{favorites.is("organization", props.organizationId) ? branding.defaultToggleIcon ? IconBookmarkFilled({ fill: "#fff" }) : IconBookmarkToggle({ fill: "#fff" }) : IconBookmarkToggle({ fill: "#fff" })}</HeaderIconDiv>
                    <HeaderItemsTitle>{strings.organizationDetailPageContent.bookmark}</HeaderItemsTitle>
                </CompanyHeaderCol>
                {branding.organizationDetailPageContent.vCardDownloadAvailable &&
                    hasAccessToOrganization(userState.user()!, props.organizationId) &&
                    <CompanyHeaderCol xs={2} style={{ maxWidht: "13%" }} className="mt-4" onClick={() => doVCard(props.exhibitor!)}>
                        <HeaderIconDiv className="mb-1 h-50">{IconDownloadVCard({ fill: "#fff" })}</HeaderIconDiv>
                        <HeaderItemsTitle>{strings.contactEntry.downloadVCardTitle}</HeaderItemsTitle>
                    </CompanyHeaderCol>}
                {props.isStaff &&
                    <CompanyHeaderCol xs={2} style={{ maxWidth: "13%" }} className="mt-4" onClick={() => { history.push(buildDetailLink(props.organizationId, props.organizationName, 'organization') + '/backoffice') }}>
                        <HeaderIconDiv className="mb-1 h-50">{IconBackoffice({ stroke: "#FFF", fill: "#FFF" })}</HeaderIconDiv>
                        <HeaderItemsTitle>{strings.organizationDetailPageContent.backoffice}</HeaderItemsTitle>
                    </CompanyHeaderCol>
                }
            </CompanyHeaderRow>
            {showRequestOrganizationModal && <SayHelloModal organizationModal={true} targetId={props.organizationId} handleClose={() => setShowRequestOrganizationModal(false)}></SayHelloModal>}
            <CalendarModal />
            <OrganizationModal />
            <PrivacyModal />
            {showGuestModal && <GuestModal close={() => setShowGuestModal(false)} />}
        </CompanyHeaderContainer >
    )
}

/* #endregion */



export async function joinShowroom(exhibitor: Company, loggedInUser: User, chime: any, meeting: any) {
    const queryParams: any = queryString.parse(window.location.search)
    if (!hasAvailableStaff(exhibitor) || (!isLivePhase && !(getIamPartOf(queryParams) === "onboarding")))
        return
    trackVisit(loggedInUser.profileId, exhibitor.id, "EXPO", undefined)
    // Enter as Staff
    for (let person of exhibitor.persons) {
        if (person.userId === loggedInUser.profileId) {
            chime.createOrJoinMeeting(exhibitor.id, "showroom")
            return
        }
    }

    // Enter as interested party
    const resp = await listAttendeeInfos(getExternalMeetingId(exhibitor.id, "showroom"))
    if ((resp as BackendServiceError).httpStatus) {
        // Logged in Backendservices
    }
    const attendees = resp as AttendeeData[]
    let hasStaffOnline = false
    const availableStaff = []
    for (let person of exhibitor.persons) {
        for (let attendee of attendees) {
            // If staff is already online, all's good
            if (person.userId === attendee.id) {
                hasStaffOnline = true
                break
            }

        }
        if (hasStaffOnline)
            break
        if (person.showroomStandby) {
            availableStaff.push(person.userId)
        }
    }
    if (hasStaffOnline)
        chime.createOrJoinMeeting(exhibitor.id, "showroom")
    else if (availableStaff.length > 0)
        meeting.sendInvite(availableStaff[0], { meetingId: exhibitor.id, meetingKind: "showroom" })
}

const OrganizationDetailPageContent: React.FunctionComponent = (props) => {
    const strings = useLanguageState().getStrings()

    const { organizationId }: any = useParams()
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState("")
    const [exhibitor, setExhibitor] = useState<Company>()
    const [paddingBottom, setPaddingBottom] = useState(aspectRatio);
    const [headerOpen, setHeaderOpen] = useState(true);

    //Privacy policy stuff and other modals
    const appState = useAppState();
    const [showGuestModal, setShowGuestModal] = useState<boolean>(false)

    const [isStaff, setIsStaff] = useState(false)

    const lang = useLanguageState().getLanguage()
    const windowSize = useWindowDimensions();

    const userState = useLoggedInState()
    const loggedInUserId = userState.user()?.profileId;
    const favorites = useFavoriteState()
    // Add banner state 
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()

    const headerRef = useRef<HTMLDivElement>(null);


    useEffect(() => {

        if (loggedInUserId) {
            // Checking if the user is staff of the organization
            getUserOrgaInfo({ id: loggedInUserId, organizationId: organizationId }).then(res => {
                res.hasOwnProperty('content') ? setIsStaff(true) : setIsStaff(false)
            }).catch(err => {
                setIsStaff(false)
            })
        }
    }, [loggedInUserId, organizationId])


    useEffect(() => {
        setIsLoaded(true)
        loadExhibitorData({
            organizationid: organizationId,
            hideNewsdata: false,
            showPersonsEventDates: true
        }).then((resp) => {
            //setIsBookmarked(favorites.is("organization", organizationId))
            setExhibitor(resp.content)
        }).catch((e: { message: React.SetStateAction<string> }) => {
            setIsLoaded(true)
            setError(e.message)
        })
        // eslint-disable-next-line
    }, [organizationId, lang])

    const onAccessDenied = () => {
        setShowGuestModal(true)
    }


    function handleScroll(e: any) {
        const shrinkOn = 30;
        if (headerRef.current && ((appState.isNetworkingOpen() && windowSize.width > 1300) || (!appState.isNetworkingOpen() && windowSize.width > 1050))) {
            if (e.scrollTop >= shrinkOn) {
                headerRef.current.style.paddingBottom = aspectRatioShrinked;
                setPaddingBottom(aspectRatioShrinked);
                setHeaderOpen(false)
            }
            else {
                headerRef.current.style.paddingBottom = aspectRatio;
                setPaddingBottom(aspectRatio);
                setHeaderOpen(true)
            }
        }
    }

    let content: JSX.Element = <div />

    const locations = calcBreadcrumbLocations(strings)
    const breadcrumb = [{ to: exhibitorsPageRoute, name: strings.sideIconBar.showfloorMenuText }]
    if (exhibitor) {
        breadcrumb.push({ to: locations[1], name: exhibitor.name })
    }

    if (error !== "") {
        content = <div style={{ marginTop: "25%" }}>
            <BackendError />
        </div>
    } else if (exhibitor) {
        if (!isLoaded) {
            content = <CenteredLoader />
        }



        const headerImageUrl = (exhibitor: Company): string => {
            if (branding.organizationDetailPageContent.defaultHeaderBasisPremiumTypes.includes(exhibitor.basisPremium) || !exhibitor.backgroundImageURL)
                return '/branding/default_brand_card.jpg'
            else
                return exhibitor.backgroundImageURL
        }
        content = <ScrollContainerRoot>
            {/* Sticky header */}
            <HeaderSticky ref={headerRef} paddingBottom={paddingBottom}>
                {exhibitor.headerVideoUrl && <VideoModal
                    width={'20%'}
                    rootCss={{ right: '20px' }}
                    videoUrl={exhibitor.headerVideoUrl}
                    videoImageUrl={exhibitor.headerVideoImageUrl}
                    headerOpen={headerOpen} />}
                <HeaderRoot>
                    <img src={headerImageUrl(exhibitor)} alt="" />
                    <CompanyHeaderRoot>
                        <CompanyHeader
                            exhibitor={exhibitor}
                            isStaff={isStaff}
                            organizationId={exhibitor.id}
                            organizationName={exhibitor.name}
                            isBookmarked={favorites.is("organization", exhibitor.id)} />
                    </CompanyHeaderRoot>

                </HeaderRoot>
            </HeaderSticky>
            <div style={{ borderBottom: "1px solid currentColor" }}>
                <Breadcrumb breadcrumb={breadcrumb} />
            </div>
            {/* Main content */}
            <DetailPageSections
                type="organization"
                guestUserBannerRef={guestUserBannerRef}
                handleScroll={handleScroll}
                onAccessDenied={onAccessDenied}
                entity={exhibitor}
                adjustHeightWith={320}
            />

            {showGuestModal && <GuestModal close={() => setShowGuestModal(false)} />}
        </ScrollContainerRoot >
    } else {
        content = <CenteredLoader />
    }



    return (
        <>
            <TopBar />
            <DetailsRoot paddingBottom={paddingBottom} windowSize={windowSize.width} isNetworkingOpen={appState.isNetworkingOpen()}>
                <GuestUserBanner setRef={setGuestUserBannerRef} />
                {content}
            </DetailsRoot>
        </>
    );
}

export function hasAvailableStaff(exhibitor?: Company) {
    if (!exhibitor?.persons?.length)
        return false
    for (let person of exhibitor.persons) {
        if (person.showroomStandby) {
            return true
        }
    }
    return false
}

export default OrganizationDetailPageContent
